import * as lf from "lovefield";
import * as Data from "./content.json";

async function createDb () {
    let schemaBuilder = lf.schema.create("Content", 1);

    schemaBuilder
        .createTable("Words")
        .addColumn("id", lf.Type.INTEGER)
        .addColumn("content", lf.Type.STRING)
        .addColumn("cat_id", lf.Type.STRING)
        .addColumn("point", lf.Type.STRING)

    let worddb: any;
    let item: any;
    schemaBuilder
        .connect()
        .then(function (db) {
        worddb = db;
        item = db.getSchema().table("Words");
        let rows = [];
        let i = 0;

        while (i < Data.RECORDS.length) {
            let row = item.createRow({
            id: Data.RECORDS[i].ID,
            content: Data.RECORDS[i].content,
            cat_id: Data.RECORDS[i].cat_id,
            point: Data.RECORDS[i].point,
            });
            i++;
            rows.push(row);
        }

        return db.insert().into(item).values(rows).exec();
        })
        .then(function () {
        return worddb.select().from(item).where().exec();
        })
        .then(function (results) {
        });
}

export default createDb;
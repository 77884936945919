import React, { Component } from 'react';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import Backpage from '../components/backpage';
import {Link} from 'react-router-dom';
import {gameQuickInstance} from "../game";
import Game_Normal from '../game/game_normal';

interface IGameResultPageState {
    winner: string
}

const initialState: IGameResultPageState = {
    winner: ''
};

interface IGameResultPageProps {
    updateGameNormal: (gameNormalInstance: Game_Normal) => void
}

class GameResult extends Component<IGameResultPageProps,IGameResultPageState> {

    constructor(props: IGameResultPageProps) {
        super(props);
        this.state = initialState;
    }

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 6rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Table = styled.div`
        width: 100%;      
        display: flex;
        flex-direction: column;
        align-items: center;

        & div {
            width: 100%;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: #fff;
            border-radius: 2rem;
        }

        & table {
            width: 100%;
            text-align: right;
            margin: .5rem 0;
            background-color: #fff;
            border-radius: 2rem;
            padding: 1rem;
        }

        & table thead tr td {
            padding: .5rem 0;
            
        }

        & table tbody tr td {
            color: #777;
            padding: .3rem .3rem;
            border-top: .1rem solid #ddd;
        }
    `;

    Startbtn = styled.button`
    padding: 0 1rem;
    height: 3rem;
    background-color: #fff;
    margin: 1rem 0;
    border: none;
    border-radius: 2rem;
    font-family: inherit;
    font-size: 1.2rem;

    & a {
        text-decoration: none;
        color: #111;
    }
    `;

    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.Table>
                        <div>
                            <img src={star} alt='Star'/>
                            <p>{gameQuickInstance.winner}</p>
                            <img src={star} alt='Star'/>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td>گروه</td>
                                    <td>امتیاز</td>
                                </tr>
                            </thead>
                            <tbody>
                                {gameQuickInstance.teams.map((item: any,index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.score}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </this.Table>
                    <this.Startbtn>
                        <Link to='/'>صفحه اصلی</Link>
                    </this.Startbtn>
                </this.Container>
            </>
        );
    }
}

export default GameResult;

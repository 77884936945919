import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import {gamesHistoryInstanse,gameFightInstance, gameNormalInstance, gameQuickInstance, gameTripleInstance} from '../game/';
import { Link } from "react-router-dom";

interface IHistoryPageState {
};
  
interface IHistoryPageProps {
    history: any;
}

class History extends Component <IHistoryPageProps, IHistoryPageState>{
    
    state = {
        selectedHistory: 'finishedGames',
        games: [],
    }

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 7rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Item = styled.div`
        width: 100%;
        border-radius: 2rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .5rem;
        margin: .5rem 0;

        & span { 
            width: 95%;
            display: flex;
            align-items: center;
            margin: .5rem 0;

            & i {
                color: #fcb400;
                margin: .3rem;
            }

            & p {
                margin-right: .1rem;
                text-align: right;
            }
        }
    `;

    BtnContainer = styled.div`
        width: 100%;
        height: 3.5rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        & button {
            height: 99%;
            padding: 0 1rem;
            border: none;
            background-color: transparent;
            color: #333;
            font-family: inherit;
            font-size: 1rem;
            border-bottom: .2rem solid transparent;
            transition: all .2s;

            &:focus {
                outline: none;
            }
        }
    `;

    Table = styled.div`
    width: 100%;      
    display: flex;
    flex-direction: column;
    align-items: center;

    & div {
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border-radius: 2rem;
        margin: .2rem 0;
    }

    & table {
        width: 100%;
        text-align: right;
        margin: .5rem 0 0;
        background-color: #fff;
        border-radius: 2rem;
        padding: 1rem;
    }

    & table thead tr td {
        padding: .5rem 0;
        width: 70%;
    }

    & table tbody tr td {
        color: #777;
        padding: .3rem .3rem;
        border-top: .1rem solid #ddd;
    }
    `;

    Separator = styled.div`
        width: 40%;
        height: .2rem;
        background-color: #eee;
        border-radius: 2rem;
        margin: 1rem 0;
    `;

    Banner = styled.div`
        width: 100%;
        height: 3rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1.3rem;
        margin: .5rem 0;
    `;

    Startbtn = styled.button`
        width: 6rem; 
        padding: 0 1rem;
        height: 3rem;
        background-color: #fff;
        margin: .5rem;
        border: none;
        border-radius: 2rem;
        font-family: inherit;
        font-size: 1rem;

        & a {
        text-decoration: none;
        color: #333;
        }

        &:focus {
            outline: none;
        }
    `;

    handleHistory = (history: string) => {
        this.setState({
            selectedHistory: history
        })
    }

    componentDidMount() {
        this.setState({
            games: gamesHistoryInstanse.getGamesHistory('Games'),
        })
    }

    handleRemove = (id: number) => {
        gamesHistoryInstanse.removeGames(id);
        this.setState({
            games: gamesHistoryInstanse.getGamesHistory('Games'),
        })
    }

    handleContinueGame = (mode: string,gameId: number,step: string = '') => {
        switch (mode) {
            case 'fight':
                gameFightInstance.setIsComingFromHistory(gameId);
                gameFightInstance.setDataFromHistory();
                this.props.history.push('/round_fight');
                break;
            case 'normal':
                gameNormalInstance.setIsComingFromHistory(gameId);
                gameNormalInstance.setDataFromHistory();
                if(step === 'playGame') {    
                    this.props.history.push('/round_normal');
                } else {
                    this.props.history.push('/category');
                }
                break;
            case 'quick':
                gameQuickInstance.setIsComingFromHistory(gameId);
                gameQuickInstance.setDataFromHistory();
                this.props.history.push('/round_quick');
                break;
            case 'triple':
                gameTripleInstance.setIsComingFromHistory(gameId);
                gameTripleInstance.setDataFromHistory();
                this.props.history.push('/round_triple');
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.BtnContainer>
                        <button style={this.state.selectedHistory === 'finishedGames' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handleHistory('finishedGames')}>بازی های تمام شده</button>
                        <button style={this.state.selectedHistory === 'notFinishedGames' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handleHistory('notFinishedGames')}>بازی های ناتمام</button>
                    </this.BtnContainer>
                    {this.state.selectedHistory === 'finishedGames' && localStorage.getItem('Games') !== null ?
                        <>
                            {this.state.games.map((item: any) => {
                                if(item.gameFinished === true) {
                                    return (
                                        <>
                                            <this.Table>
                                                <div>
                                                    <img src={star} alt='Star'/>
                                                    <p>
                                                        نوع بازی : 
                                                    {item.mode === 'normal' ? ' معمولی' : item.mode === 'quick' ? ' سرعتی' : item.mode === 'fight' ? ' مبارزه ای ' : 'سه گانه'}
                                                    </p>
                                                    <img src={star} alt='Star'/>
                                                </div>
                                                <div>
                                                    <img src={star} alt='Star'/>
                                                    <p>{item.winner}</p>
                                                    <img src={star} alt='Star'/>
                                                </div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>گروه</td>
                                                            <td>امتیاز</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.teams.map((item: any,index: number) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.score}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </this.Table>
                                            <this.Separator/>
                                        </>
                                    )
                                }
                                return null;
                            })}
                        </>
                    : null}
                    
                    {this.state.selectedHistory === 'notFinishedGames' && localStorage.getItem('Games') !== null ?
                        <>
                            {this.state.games.map((item: any) => {
                                if(item.gameFinished === false) {
                                    return (
                                        <>
                                            <this.Table>
                                                <div>
                                                    <img src={star} alt='Star'/>
                                                    <p>
                                                        نوع بازی : 
                                                    {item.mode === 'normal' ? ' معمولی' : item.mode === 'quick' ? ' سرعتی' : item.mode === 'fight' ? ' مبارزه ای' : ' سه گانه'}
                                                    </p>
                                                    <img src={star} alt='Star'/>
                                                </div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            {item.mode === 'fight' ? <td>گروه های باقی مانده</td> : <td>گروه ها</td>}
                                                            {item.mode !== 'fight' ? <td>امتیاز</td> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.teams.map((team: any,index: number) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{team.name}</td>
                                                                    {item.mode !== 'fight' ? <td>{team.score}</td> : null}
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </this.Table>
                                            <div>
                                                <this.Startbtn onClick={() => this.handleContinueGame(item.mode,item.gameId,item.step)} style={{backgroundColor: '#43a047',color: '#fff'}}>
                                                    ادامه بازی
                                                </this.Startbtn>
                                                <this.Startbtn onClick={() => this.handleRemove(item.gameId)} style={{backgroundColor: '#f50057'}}>
                                                    <Link to='/history' style={{color: '#fff'}}>حذف</Link>
                                                </this.Startbtn>
                                            </div>
                                            <this.Separator/>
                                        </>
                                    )
                                }
                                return null;
                            })}
                        </>
                    : null}
                </this.Container>
            </>
        );
    }
}

export default History;

import React, { Component } from 'react';
import soundfile from '../assets/sounds/main.mp3';
import styled from 'styled-components'

interface IMainsoundcomponentState {
    playing: boolean
}

const initialState: IMainsoundcomponentState = {
    playing: false
};

interface IMainsoundcomponentProps {
}

class MainSound extends Component<IMainsoundcomponentProps,IMainsoundcomponentState> {

    constructor(props: IMainsoundcomponentProps) {
        super(props);
        this.state = initialState;
    }

    handleSoundPlay = () => {
        this.setState(prev => {
            return {
                playing: !prev.playing
            }
        })
    }

    Button = styled.button`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 2.5rem;
        background-color: #fff;
        border-radius: 1.5rem;
        border: none;
        position: absolute;
        right: 4%;
        top: 4%;
        font-size: 2rem;
        color: #333;
        &:focus {
            outline: none;
        }

        & i {
            
        }
    `;

    render() {
        return (
            <>
                <this.Button onClick={() => this.handleSoundPlay()}>
                    {this.state.playing ? <i className="fas fa-volume-down"/> : <i className="fas fa-volume-mute"/>}
                </this.Button>
                <audio autoPlay loop={true} muted={this.state.playing ? false : true}>
                    <source src={soundfile} type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio>
            </>
        );
    }
}

export default MainSound;

import React, { Component } from "react";
import styled from "styled-components";
import star from "../assets/img/star.png";
import Backpage from "../components/backpage";
import { Link } from "react-router-dom";
import createdb from "../database/createdb";
import { configNormalInstance, gameNormalInstance, teamsInstance } from "../game";
import Config_Normal from "../game/config_normal";

const initialState: Config_Normal = configNormalInstance;

interface ISettingPageProps {
}

class Setting extends Component<ISettingPageProps, Config_Normal> {
  constructor(props: ISettingPageProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    createdb();
    teamsInstance.initializeTeams();
  }

  Container = styled.div`
    max-width: 40rem;
    width: 95vw;
    padding: 5rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  Banner = styled.div`
    width: 100%;
    height: 3rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3rem;
    margin: 1.5rem 0 0.5rem;
  `;

  Modes = styled.div`
    width: 100%;
    height: 12rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3rem;
  `;

  Counter = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      width: 2.5rem;
      height: 2.5rem;
      background-color: #fff;
      border: none;
      border-radius: 2rem;

      &:focus {
        outline: none;
      }
    }

    & p {
      width: 3.5rem;
      height: 3.5rem;
      background-color: #fff;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  Input = styled.input`
    width: 90%;
    height: 2.5rem;
    padding: 0 1rem;
    background-color: #fff;
    border-radius: 2rem;
    border: none;
    margin: 0.5rem 0;
    font-family: inherit;

    &:focus {
      outline: none;
    }
  `;

  Startbtn = styled.button`
    padding: 0 1rem;
    height: 3rem;
    background-color: #fff;
    margin: 1rem 0;
    border: none;
    border-radius: 2rem;
    font-family: inherit;
    font-size: 1.2rem;

    & a {
      text-decoration: none;
      color: #333;
    }
  `;

  handleGroupNameChange = (e: any, index: number) => {
    teamsInstance.editTeams(index, e.target.value);
    this.forceUpdate();
  };

  handleLaps = (state: boolean) => {
    configNormalInstance.changeLapsValue(state);
    this.forceUpdate();
  };

  handleThinkingTime = (state: boolean) => {
    configNormalInstance.changeThinkingTimeValue(state);
    this.forceUpdate();
  };

  handleTeams = (state: boolean) => {
    switch (state) {
      case true:
        teamsInstance.addTeams();
        break;
      case false:
        teamsInstance.removeTeams();
        break;
      default:
    }
    this.forceUpdate();
  };

  handleNextStep = () => {
    localStorage.setItem('Teams',JSON.stringify(teamsInstance.teams));
    gameNormalInstance.configure(configNormalInstance,teamsInstance);
    this.forceUpdate();
  };

  render() {
    return (
      <>
        <Backpage to="/modes" />
        <this.Container>
          <this.Banner>
            <img src={star} alt="Star" />
            <p>تعداد گروه ها</p>
            <img src={star} alt="Star" />
          </this.Banner>
          <this.Counter>
            <button onClick={() => this.handleTeams(true)}>
              <i className="fa fa-plus"></i>
            </button>
            <p>{teamsInstance.teams.length}</p>
            <button onClick={() => this.handleTeams(false)}>
              <i className="fa fa-minus"></i>
            </button>
          </this.Counter>
          {teamsInstance.teams.map((item: any, index: number) => {
            return (
              <this.Input
                key={index}
                defaultValue={item.name}
                onChange={(e) => this.handleGroupNameChange(e, index)}
              />
            );
          })}
          <this.Banner>
            <img src={star} alt="Star" />
            <p>تعداد دورها</p>
            <img src={star} alt="Star" />
          </this.Banner>
          <this.Counter>
            <button onClick={() => this.handleLaps(true)}>
              <i className="fa fa-plus"></i>
            </button>
            <p>{configNormalInstance.laps}</p>
            <button onClick={() => this.handleLaps(false)}>
              <i className="fa fa-minus"></i>
            </button>
          </this.Counter>
          <this.Banner>
            <img src={star} alt="Star" />
            <p>زمان فکر کردن</p>
            <img src={star} alt="Star" />
          </this.Banner>
          <this.Counter>
            <button onClick={() => this.handleThinkingTime(true)}>
              <i className="fa fa-plus"></i>
            </button>
            <p>{configNormalInstance.thinkingTime}</p>
            <button onClick={() => this.handleThinkingTime(false)}>
              <i className="fa fa-minus"></i>
            </button>
          </this.Counter>
          <this.Startbtn onClick={() => this.handleNextStep()}>
            <Link to="/game_normal_result">مرحله بعد</Link>
          </this.Startbtn>
        </this.Container>
      </>
    );
  }
}

export default Setting;

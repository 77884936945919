import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "../css/round.css";
import { gameNormalInstance, category } from "../game";

interface IRoundPageState {
  thinkingTimeInterval: number;
  myInterval: any;
}

const initialState: IRoundPageState = {
  myInterval: undefined,
  thinkingTimeInterval: 0,
};

interface IRoundPageProps {
  history: any;
}

class Round extends Component<IRoundPageProps, IRoundPageState> {
  constructor(props: IRoundPageProps) {
    super(props);
    this.state = initialState;
  }

  Container = styled.div`
    max-width: 40rem;
    padding: 7rem 0 1rem;
    width: 95vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `;

  Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  TopButtons = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0;
  `;

  ControlButtons = styled.div`
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: 2rem;

    & p {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  `;

  Word = styled.p`
    width: 100%;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #333;
  `;

  Button = styled.button`
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: #333;
    position: relative;

    & div {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      left: 0%;
      top: 0%;
      background-color: #e53935;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  FinishingButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  `;

  thinkingTimeBox = styled.div`
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    border-radius: 50%;
    font-size: 2rem;
  `;

  componentDidMount() {    
    if(gameNormalInstance.isComingFromHistory) {
      gameNormalInstance.setDataFromHistory();
    } else {
      gameNormalInstance.initializeRound();
    }
    // this interval for thinkingTime timer
    let thinkingTimeInterval = setInterval(() => {
      this.forceUpdate();
    }, 1000);
    this.setState({
      thinkingTimeInterval: thinkingTimeInterval,
    });
  }

  handlePlay = () => {
    clearInterval(this.state.thinkingTimeInterval);
    gameNormalInstance.play();
    // this interval for timer
    setInterval(() => {
      this.forceUpdate();
    }, 1000);
  };

  handleFinished = () => {
    gameNormalInstance.end();
  };

  handleEndRound = (state: boolean) => {
    switch (state) {
      case true:
        gameNormalInstance.handleRight(
          category.selectedPoint,
          gameNormalInstance.time,
          gameNormalInstance.turn - 1
        );
        break;
      case false:
        gameNormalInstance.handleFalse();
        break;

      default:
        break;
    }
  };

  handleError = () => {
    gameNormalInstance.errorHappend();
    document.getElementById('App')?.classList.add('Active');
    setTimeout(() => {
      document.getElementById('App')?.classList.remove('Active');
    },1000);
    this.forceUpdate();
  };

  render() {
    return (
      <this.Container>
        <this.Word>{gameNormalInstance.word}</this.Word>
        {!gameNormalInstance.roundStarted ? (
          <this.thinkingTimeBox>
            {gameNormalInstance.thinkingTime}
          </this.thinkingTimeBox>
        ) : null}
        <this.Buttons>
          <this.TopButtons>
            {gameNormalInstance.roundStarted ? (
              <this.ControlButtons>
                <this.Button onClick={() => this.handleError()}>
                  <i
                    className="fa fa-exclamation"
                    style={{ fontSize: "1.5rem" }}
                  />
                  {gameNormalInstance.errors > 0 ? (
                    <div>{gameNormalInstance.errors}</div>
                  ) : undefined}
                </this.Button>
                <p>خطا</p>
              </this.ControlButtons>
            ) : (
              <this.ControlButtons>
                <this.Button
                  disabled={gameNormalInstance.isWordChanged ? true : false}
                  style={
                    gameNormalInstance.isWordChanged
                      ? { backgroundColor: "#ccc", color: "#666" }
                      : undefined
                  }
                  onClick={() =>
                    gameNormalInstance.changeWord(
                      category.selectedCategory,
                      category.selectedPoint
                    )
                  }
                >
                  <i className="fa fa-redo-alt" />
                </this.Button>
                <p>تعویض کلمه</p>
              </this.ControlButtons>
            )}
            <div
              className="timer"
              style={
                gameNormalInstance.roundStarted && !gameNormalInstance.roundFinished
                  ? { animationName: "timer" }
                  : undefined
              }
            >
              {gameNormalInstance.time}
            </div>
            <this.ControlButtons>
              <this.Button
                onClick={() => {
                  gameNormalInstance.roundStarted
                    ? this.handleFinished()
                    : this.handlePlay();
                }}
              >
                {gameNormalInstance.roundStarted ? (
                  <i className="fa fa-stop" />
                ) : (
                  <i className="fa fa-play" />
                )}
              </this.Button>
              <p>{gameNormalInstance.roundStarted ? "پایان" : "شروع"}</p>
            </this.ControlButtons>
          </this.TopButtons>
          {gameNormalInstance.roundFinished ? (
            <this.FinishingButtons>
              <Link
                to="/game_normal_result"
                style={{ textDecoration: "none" }}
                onClick={() => this.handleEndRound(true)}
              >
                <this.Button>
                  <i
                    className="fa fa-check"
                    style={{ color: "#388E3C", fontSize: "1.5rem" }}
                  />
                </this.Button>
              </Link>
              <Link
                to="/game_normal_result"
                style={{ textDecoration: "none" }}
                onClick={() => this.handleEndRound(false)}
              >
                <this.Button>
                  <i
                    className="fa fa-times"
                    style={{ color: "#E53935", fontSize: "1.5rem" }}
                  />
                </this.Button>
              </Link>
            </this.FinishingButtons>
          ) : null}
        </this.Buttons>
      </this.Container>
    );
  }
}

export default Round;

import React, { Component } from 'react';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import Backpage from '../components/backpage';
import {Link} from 'react-router-dom';
import createdb from '../database/createdb';
import {configQuickInstance, gameQuickInstance,teamsInstance, category} from '../game';

interface ISettingPageState {
}

const initialState: ISettingPageState = {
};

interface ISettingPageProps {
}

class Setting extends Component<ISettingPageProps,ISettingPageState> {

    constructor(props: ISettingPageProps) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {        
        createdb();
        teamsInstance.initializeTeams();
    }

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 5rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Banner = styled.div`
        width: 100%;
        height: 3rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1.3rem;
        margin: 1.5rem 0 .5rem;
    `;

    Modes = styled.div`
        width: 100%;
        height: 12rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1.3rem;
    `;

    Counter = styled.div`
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & button {
            width: 2.5rem;
            height: 2.5rem;
            background-color: #fff;
            border: none;
            border-radius: 2rem;

            &:focus {
                outline: none;
            }
        }

        & p {
            width: 3.5rem;
            height: 3.5rem;
            background-color: #fff;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `;

    Input = styled.input`
        width: 90%;
        height: 2.5rem;
        padding: 0 1rem;
        background-color: #fff;
        border-radius: 2rem;
        border: none;
        margin: .5rem 0;
        font-family: inherit;

        &:focus {
            outline: none;
        }
    `;

    BtnContainer = styled.div`
        width: 100%;
        display: flex;
        align-items: ceenter;
        justify-content: center;
        margin-bottom: 2rem;

        & button {
            min-width: 15%;
            padding: 0 1rem;
            height: 3rem;
            background-color: #fff;
            font-family: inherit;
            font-size: .9rem;
            border: none;
            border-radius: 2rem;
            margin: 0 .5rem;
            transition: all .3s;

            &:focus {
                outline: none;
            }
        }
    `;

    Startbtn = styled.button`
        padding: 0 1rem;
        height: 3rem;
        background-color: #fff;
        margin: 1rem 0;
        border: none;
        border-radius: 2rem;
        font-family: inherit;
        font-size: 1.2rem;
        
        & a {
            text-decoration: none;
            color: #333;
        }
    `;

    handleTeams = (state: boolean) => {
        switch (state) {
            case true:
                teamsInstance.addTeams();
                break;
            case false:
                teamsInstance.removeTeams();
                break;
            default:
        }
        this.forceUpdate();
    }

    handleEditTeams = (e: any,index: number) => {
        teamsInstance.editTeams(index,e.target.value);
        this.forceUpdate();
    }

    handleGameTime = (state: boolean) => {
        configQuickInstance.changeGameTimeValue(state);
        this.forceUpdate();
    }

    handleDifficulty = (difficulty: number) => {
        configQuickInstance.changeDifficultyValue(difficulty);
        this.forceUpdate();
    }

    handleSelectingCategories = (value: string) => {
        configQuickInstance.changeSelectingCategoriesValue(value);
        this.forceUpdate();
    }

    handleNextStep = () => {
        localStorage.setItem('Teams',JSON.stringify(teamsInstance.teams));
        gameQuickInstance.configure(teamsInstance);
        this.forceUpdate();
    };

    render() {
        return (
            <>
                <Backpage to='/modes'/>
                <this.Container>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>تعداد گروه ها</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.Counter>
                        <button onClick={() => this.handleTeams(true)}>
                            <i className='fa fa-plus'></i>
                        </button>
                        <p>{teamsInstance.teams.length}</p>
                        <button onClick={() => this.handleTeams(false)}>
                            <i className='fa fa-minus'></i>
                        </button>
                    </this.Counter>
                    {teamsInstance.teams.map((item: any,index: number) => {
                        return (
                            <this.Input key={index} defaultValue={item.name} onChange={(e: any) => this.handleEditTeams(e,index)}/>
                        )
                    })}
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>زمان بازی</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.Counter>
                        <button onClick={() => this.handleGameTime(true)}>
                            <i className='fa fa-plus'></i>
                        </button>
                        <p>{configQuickInstance.gameTime}</p>
                        <button onClick={() => this.handleGameTime(false)}>
                            <i className='fa fa-minus'></i>
                        </button>
                    </this.Counter>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>درجه سختی بازی</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.BtnContainer>
                        <button style={configQuickInstance.difficulty === 1 ? {backgroundColor: '#ccc'} : undefined} onClick={() => this.handleDifficulty(1)}>ساده</button>
                        <button style={configQuickInstance.difficulty === 2 ? {backgroundColor: '#ccc'} : undefined} onClick={() => this.handleDifficulty(2)}>معمولی</button>
                        <button style={configQuickInstance.difficulty === 3 ? {backgroundColor: '#ccc'} : undefined} onClick={() => this.handleDifficulty(3)}>حرفه ای</button>
                    </this.BtnContainer>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>انتخاب دسته بندی ها</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.BtnContainer>
                        <button style={configQuickInstance.selectingCategories === 'auto' ? {backgroundColor: '#ccc'} : undefined} onClick={() => this.handleSelectingCategories('auto')}>خودکار</button>
                        <button style={configQuickInstance.selectingCategories === 'manual' ? {backgroundColor: '#ccc'} : undefined} onClick={() => this.handleSelectingCategories('manual')}>دستی</button>
                    </this.BtnContainer>
                    <this.Startbtn onClick={() => this.handleNextStep()}>
                        {configQuickInstance.selectingCategories === 'auto' ?
                            <Link to='/round_quick'>مرحله بعد</Link> :
                            <Link to='/multiple_category' onClick={() => category.setGoTo('/round_quick')}>مرحله بعد</Link>
                        }
                    </this.Startbtn>
                </this.Container>
            </>
        );
    }
}

export default Setting;
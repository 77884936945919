import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import categories from '../database/categories.json';
import {category} from '../game';
import {Link} from 'react-router-dom';

interface ICategoryPageState {
}

const initialState: ICategoryPageState = {
};

interface ICategoryPageProps {
    route: string;
}

class MultipleCategory extends Component<ICategoryPageProps,ICategoryPageState> {

    Container = styled.div`
        max-width: 40rem;
        padding: 6rem .8rem 2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        position: relative;
    `;

    Banner = styled.div`
    width: 100%;
    height: 3rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3rem;
    margin: 1.5rem 0 .5rem;
    `;

    CategoryItemContainer = styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    `;

    CategoryItem = styled.div`
        width: 7rem;
        height: 6rem;
        background-color: #fff;
        border-radius: 2rem;
        margin: .2rem .1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #555;
        cursor: pointer;

        & i {
            font-size: 2.2rem;
        }

        & p {
            font-size: .8rem;
            margin-top: -.5rem;
        }
    `;

    Startbtn = styled.button`
        padding: 0 1rem;
        height: 3rem;
        background-color: #fff;
        margin: 1rem 0;
        border: none;
        border-radius: 2rem;
        font-family: inherit;
        font-size: 1.2rem;

        & a {
            text-decoration: none;
            color: #333;
        }
    `;

    constructor(props: ICategoryPageProps) {
        super(props);
        this.state = initialState;
    }

    handleClick = (id: number) => {
        category.handleSelectedCategories(id);
        this.forceUpdate();
    }

    render() {
        return (
            <>
                <Backpage to='gameresult'/>
                <this.Container>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>انتخاب دسته بندی</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.CategoryItemContainer>
                        {categories.RECORDS.map((item,index) => {
                            return (
                                <this.CategoryItem style={category.selectedCategories.includes(Number(item.ID)) ? {backgroundColor: '#76ff03'} : undefined} key={index} onClick={() => this.handleClick(Number(item.ID))}>
                                    <i className={item.Img}/>
                                    <p>{item.CategoryName}</p>
                                </this.CategoryItem>
                            )
                        })}
                    </this.CategoryItemContainer>
                    <this.Startbtn>
                        <Link to={category.goTo}>مرحله بعد</Link>
                    </this.Startbtn>
                </this.Container>
            </>
        );
    }
}

export default MultipleCategory;
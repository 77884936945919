import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import styled,{keyframes} from 'styled-components';
import { gamesHistoryInstanse} from "../game";

interface IRoundPageState {
    history: boolean;
  }
  
  const initialState: IRoundPageState = {
    history: false,
  };
  
  interface IRoundPageProps {
  }

class homepage extends Component<IRoundPageProps, IRoundPageState> {

    constructor(props: IRoundPageProps) {
        super(props);
        this.state = initialState;
    }

    BreatheAnimation = keyframes`
        0% {transform: scale(1);}
        50% {transform: scale(1.2);}
        100% {transform: scale(1);}
    `;

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        height: 100vh;
        display: grid;
        align-items: center;
        justify-items: center;

        & .start {
            width: 5rem;
            text-decoration: none;
            color: #fff;
            align-self: end;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            line-height: 2rem;
            font-size: 1rem;
            &:focus {
                outline: none;
            }

            & i {
            font-size: 4.5rem;
            color: rgb(250, 250, 250);
        }
        }

        & .app-bottom {
            width: 100%;
            align-self: flex-end;
            display: flex;
            justify-content: space-around;
            margin-bottom: 1rem;

            & a {
                text-decoration: none;
                color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                line-height: 2rem;
                font-size: .8rem;
                &:focus {
                    outline: none;
                }
            }
        }

        & .app-bottom i {
            font-size: 1.8rem;
            color: rgb(250, 250, 250);
        }

        & .app-bottom .animation {
            animation-name: ${this.BreatheAnimation};
            animation-duration: 1s;
            animation-iteration-count: infinite;
        }
    `;

    ShareButton = styled.div`
        width: 3rem;
        height: 2.5rem;
        background-color: #fff;
        border-radius: 2rem;
        position: absolute;
        top: 4%;
        left: 4%;
        display: flex;
        align-items: center;
        justify-content: center;

        & a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #333;
            font-size: 1.5rem;
        }
    `;

    componentDidMount() {
        let games = gamesHistoryInstanse.getGamesHistory('Games');        
        if(games !== null && games.length > 0) {
            this.setState({
                history: true
            })
        }
    }

    render() {
        return (
            <this.Container>
                <this.ShareButton>
                    <Link to='/share'>
                        <i className='fa fa-share-alt'/>
                    </Link>
                </this.ShareButton>
                <Link to='/modes' className='start'>
                    <i className="fas fa-play"/>
                    شروع
                </Link>
                <div className='app-bottom'>
                    <Link className='animation' to='/history' style={!this.state.history ? {animationName: 'none'} : undefined}>
                        <i className="fas fa-history"/>
                        تاریخچه
                    </Link>
                    <Link to='/rules'>
                        <i className="fas fa-gavel"/>
                        قوانین
                    </Link>
                    <Link to='/words'>
                        <i className="fas fa-book"/>
                        کلمات
                    </Link>
                    <Link to='/about_us'>
                        <i className="fas fa-user"/>
                        درباره من
                    </Link>
                    <a href='https://zil.ink/me2002'>
                        <i className="fas fa-phone"/>
                        تماس باما
                    </a>
                </div>
            </this.Container>
        );
    }
}

export default homepage;

import * as lf from "lovefield";

async function getdb(cat_id: number,point: number) {
  const schemaBuilder = lf.schema.create("Content", 1);

  schemaBuilder
    .createTable("Words")
      .addColumn("id", lf.Type.INTEGER)
      .addColumn("content", lf.Type.STRING)
      .addColumn("cat_id", lf.Type.STRING)
      .addColumn("point", lf.Type.STRING)

  let db = await schemaBuilder.connect();

  let item = db.getSchema().table('Words');
  return db.select().from(item).where(lf.op.and(item.cat_id.eq(cat_id),item.point.eq(point))).exec()
  .then((res: any) => {
    const randomNum = Math.floor(Math.random() * res.length);
    return res[randomNum].content;
  })
}

export default getdb;
import React, { Component } from "react";
import styled , {keyframes} from "styled-components";
import "../css/round.css";
import { gameFightInstance } from "../game";
import star from '../assets/img/star.png';

interface IRoundPageState {
  turnChanged: boolean;
  thinkingTimeInterval: number;
  myInterval: any;
}

const initialState: IRoundPageState = {
  turnChanged: false,
  myInterval: undefined,
  thinkingTimeInterval: 0,
};

interface IRoundPageProps {
  history: any;
}

class Round extends Component<IRoundPageProps, IRoundPageState> {
  constructor(props: IRoundPageProps) {
    super(props);
    this.state = initialState;
  }

  Container = styled.div`
    max-width: 40rem;
    padding: 7rem 0 1rem;
    width: 95vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `;

  BreatheAnimation = keyframes`
    0% {transform: scale(1);}
    50% {transform: scale(1.03);}
    100% {transform: scale(1);}
  `;

  Banner = styled.div`
    width: 100%;
    height: 3rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3rem;
    margin: 1.5rem 0 0 0;
    animation-name: ${this.BreatheAnimation};
    animation-duration: .5s;
    animation-iteration-count: infinite;
  `;

  Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  TopButtons = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0;
  `;

  ControlButtons = styled.div`
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: 2rem;

    & p {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  `;

  Word = styled.p`
    width: 100%;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #333;
  `;

  Button = styled.button`
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: #333;
    position: relative;

    & div {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      left: 0%;
      top: 0%;
      background-color: #e53935;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  FinishingButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;

    & div {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        padding-top: .3rem;
        font-size: .8rem;
        color: #fff;  
      }
    }
  `;

  componentDidMount() {    
    if(gameFightInstance.isComingFromHistory) {
      gameFightInstance.setDataFromHistory();
    } else {
      gameFightInstance.initializeRound();
    }
    this.forceUpdate();
  }

  handlePlay = () => {
    gameFightInstance.play();
    // this interval for timer
    let interval = setInterval(() => {
      this.forceUpdate();
      if(gameFightInstance.roundFinished) {
        clearInterval(interval)
        this.props.history.push('/game_fight_result');
      }
    },100)
  };

  handleClick = () => {
    gameFightInstance.setWord();
    gameFightInstance.changeTurn();
    this.setState({
      turnChanged: true
    });
    setTimeout(() => {
      this.setState({
        turnChanged: false
      });
    }, 1000);
    this.forceUpdate();
  };

  render() {
    return (
      <this.Container>
        <this.Banner style={!this.state.turnChanged ? {animationName: 'none'} : undefined}>
          <img src={star} alt='Star'/>
          <p>نوبت {gameFightInstance.teams[gameFightInstance.turn-1].name}</p>
          <img src={star} alt='Star'/>
        </this.Banner>
        <this.Word>{gameFightInstance.roundStarted ? gameFightInstance.word : 'بازی شروع نشده است'}</this.Word>
        <this.Buttons>
          {!gameFightInstance.roundStarted ?
          <this.ControlButtons>
            <this.Button onClick={() => this.handlePlay()}>
              <i className="fa fa-play" style={{fontSize: '1.5rem'}}/>
            </this.Button>
            <p>شروع</p>
          </this.ControlButtons> : undefined}
          <this.TopButtons>
            <div className='timer' style={gameFightInstance.roundStarted ? {animationName: 'timer'} : undefined}>{gameFightInstance.lapsTime}</div>
          </this.TopButtons>
          {gameFightInstance.roundStarted ?
          <this.FinishingButtons>
          <div onClick={() => this.handleClick()}>
            <this.Button>
              <i className="fa fa-check" style={{color: '#388E3C',fontSize: '1.5rem'}}/>
            </this.Button>
            <p>درست گفت</p>
          </div>
        </this.FinishingButtons> : null}
        </this.Buttons>
      </this.Container>
    );
  }
}

export default Round;

class Team {
  name: string;
  score: number;

  constructor(name: string, score: number = 0) {
    this.name = name;
    this.score = score;
  }
}

export default Team;
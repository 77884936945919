import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';
import star from '../assets/img/star.png';

class Aboutus extends Component {

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 5rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Banner = styled.div`
        width: 100%;
        height: 3rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1.1rem;
        margin: 1.5rem 0 .5rem;
    `;

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>طراح و توسعه دهنده محمد احتشام</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                </this.Container>
            </>
        );
    }
}

export default Aboutus;

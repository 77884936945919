class Games_History {
    games: Array<any> = [];
    
    constructor() {
        this.games = this.getGamesHistory('Games');
    }

    saveGames (game: any) {
        let games = this.getGamesHistory('Games');
        if(games === null || game.gameId > games.length) {
            this.addGame(game);
        } else {
            this.changeGame(game);
        }
        localStorage.setItem('Games',JSON.stringify(this.games));
    }

    changeGame (game: any) {
        let games = this.getGamesHistory('Games');
        this.games = games;
        this.games.map((item: any,index: any) => {
            if(item.gameId === game.gameId) {                
                games = [
                    ...this.games,
                    this.games[index] = game
                ]
            }
            return null;
        })
    }

    addGame (game: any) {
        let games = this.getGamesHistory('Games');
        if(games === null) {
            this.games = [
                game
            ]
        } else {
            this.games = [
                ...games,
                game
            ]
        }
    }

    removeGames (id: number) {
        let newGames: Array<any> = [];
        this.games.map((item: any,index: any) => {
            if(item.gameId !== id) {
                newGames.push(this.games[index]);
            }
            return null;
        })
        this.games = newGames;
        localStorage.setItem('Games',JSON.stringify(this.games));
    }

    getGamesHistory (key: string) {
        let result: any;
        result = localStorage.getItem(key);
        return JSON.parse(result);        ;
    }
}

export default Games_History;
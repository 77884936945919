import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import * as Data from "../database/categories.json";
import {wordsAndCategoriesInstanse} from '../game'

class Aboutus extends Component {

    state = {
        selectedPage: 'categories',
        categoryInput: '',
        wordInput: '',
        categorySelect: 'اشیاء',
        pointSelect: 1,
        wordsAndCategories: {}
    }

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 8rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    InputContainer = styled.div`
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & button {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: #fff;
            color: #555;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            font-size: 1.2rem;

            &:focus {
                outline: none;
            }
        }
    `;

    Input = styled.input`
        max-width: 90%;
        min-width: 80%;
        height: 2.5rem;
        padding: 0 1rem;
        background-color: #fff;
        border-radius: 2rem;
        border: none;
        margin: .5rem 0;
        font-family: inherit;

        &:focus {
            outline: none;
        }
    `;

    BtnContainer = styled.div`
        width: 100%;
        height: 3.5rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        & button {
            height: 99%;
            padding: 0 1rem;
            border: none;
            background-color: transparent;
            color: #333;
            font-family: inherit;
            font-size: 1rem;
            border-bottom: .2rem solid transparent;
            transition: all .2s;

            &:focus {
                outline: none;
            }
        }
    `;

    Banner = styled.div`
        width: 100%;
        height: 3rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1rem;
        margin: .5rem 0;
    `;

    Separator = styled.div`
        width: 40%;
        height: .2rem;
        background-color: #eee;
        border-radius: 2rem;
        margin: 1rem 0;
    `;

    MenuContainer = styled.div`
        width: 90%;
        margin: .5rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #fff;
    `;

    Menu = styled.select`
        width: 10rem;
        margin-right: 1rem;
        border-radius: 2rem;
        padding: .5rem 2rem;
        font-family: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: #fff;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 7px;
        border: 1px solid #dfdfdf;

        &:focus {
            outline: none;
        }
    `;

    CategoriesContainer = styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 1rem 0;

        & div {
            padding: .1rem .8rem .1rem .2rem;
            background-color: #fff;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: .5rem .3rem;

            & button {
                width: 1.5rem;
                height: 1.5rem;
                color: #fff;
                background-color: red;
                border-radius: 50%;
                border: none;
                margin-right: .8rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-right: .1rem;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }
        }
    `;

    handlePage = (page: string) => {
        this.setState({
            selectedPage: page
        })
    }

    handleInputs = (e: any,state: string) => {
        switch (state) {
            case 'categoryInput':
                this.setState({
                    categoryInput: e.target.value
                })
                break;
            case 'wordInput':
                this.setState({
                    wordInput: e.target.value
                })
                break;
            default:
        }
    }

    handleWordsMenu = (e: any,stateName: string) => {
        if(stateName === 'catId') {
            this.setState({
                categorySelect: e.target.value
            })
        } else {
            this.setState({
                pointSelect: e.target.value
            })
        }
    }

    handleAdd = (addWhat: string) => {
        if(addWhat === 'category') {
            wordsAndCategoriesInstanse.saveCategory(this.state.categoryInput);
            this.setState({
                categoryInput: ''
            })
        }
        else {
            wordsAndCategoriesInstanse.saveWord(this.state);
            this.setState({
                wordInput: ''
            })
        }
        wordsAndCategoriesInstanse.getWordsAndCategories();
        this.forceUpdate();
    }

    handleDelete = (index: number,isCategory: boolean) => {
        if(isCategory) {
            wordsAndCategoriesInstanse.removeCategory(index);
        } else {
            wordsAndCategoriesInstanse.removeWord(index);
        }
    }

    componentWillMount() {        
        wordsAndCategoriesInstanse.getWordsAndCategories();
        if(wordsAndCategoriesInstanse.wordsAndCategories) {
            wordsAndCategoriesInstanse.wordsAndCategories.categories.map((item: any) => {
                wordsAndCategoriesInstanse.addCategoryToJsonFile(item);
                return null;
            })
        }
        if(wordsAndCategoriesInstanse.wordsAndCategories) {
            wordsAndCategoriesInstanse.wordsAndCategories.words.map((item: any) => {
                wordsAndCategoriesInstanse.addWordToJsonFile(item);
                return null;
            })
        }
    }

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.BtnContainer>
                        <button style={this.state.selectedPage === 'categories' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handlePage('categories')}>دسته بندی ها</button>
                        <button style={this.state.selectedPage === 'words' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handlePage('words')}>کلمات</button>
                    </this.BtnContainer>
                    {this.state.selectedPage === 'categories' ?
                        <>
                            <this.Banner>
                                <img src={star} alt='Star'/>
                                <p>اضافه کردن دسته بندی</p>
                                <img src={star} alt='Star'/>
                            </this.Banner>
                            <this.InputContainer>
                                <this.Input value={this.state.categoryInput} placeholder='نام دسته بندی' onChange={(e) => this.handleInputs(e,'categoryInput')}/>
                                <button onClick={() => this.handleAdd('category')}>
                                    <i className='fa fa-plus'/>
                                </button>
                            </this.InputContainer>
                            <this.Separator/>
                            <this.Banner>
                                <img src={star} alt='Star'/>
                                <p>دسته بندی های شما</p>
                                <img src={star} alt='Star'/>
                            </this.Banner>
                            {localStorage.getItem('Words') !== null && wordsAndCategoriesInstanse.wordsAndCategories !== null ?
                                <this.CategoriesContainer>
                                    {wordsAndCategoriesInstanse.wordsAndCategories.categories.map((item: any,index: any) => {
                                        return (
                                            <div>
                                                <p>{item}</p>
                                                <button onClick={() => this.handleDelete(index,true)}>
                                                    <i className='fa fa-minus-circle'/>
                                                </button>
                                            </div>
                                        )
                                    })}
                                </this.CategoriesContainer> : null
                            }
                        </> : null
                    }
                    {this.state.selectedPage === 'words' ? 
                        <>
                            <this.Banner>
                                <img src={star} alt='Star'/>
                                <p>اضافه کردن کلمه</p>
                                <img src={star} alt='Star'/>
                            </this.Banner>
                            <this.InputContainer>
                                <this.Input value={this.state.wordInput} placeholder='کلمه' onChange={(e) => this.handleInputs(e,'wordInput')}/>
                                <button onClick={() => this.handleAdd('word')}>
                                    <i className='fa fa-plus'/>
                                </button>
                            </this.InputContainer>
                            <this.MenuContainer>
                                <p>دسته بندی :</p>
                                <this.Menu id='catId' onChange={(e) => this.handleWordsMenu(e,'catId')}>
                                    {Data.RECORDS.map((item: any) => {
                                        return (
                                            <option value={item.id}>{item.CategoryName}</option>
                                        )
                                    })}
                                </this.Menu>
                            </this.MenuContainer>
                            <this.MenuContainer>
                                <p>امتیاز کلمه :</p>
                                <this.Menu id='point' onChange={(e) => this.handleWordsMenu(e,'point')}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                </this.Menu>
                            </this.MenuContainer>
                            <this.Separator/>
                            <this.Banner>
                                <img src={star} alt='Star'/>
                                <p>کلمات شما</p>
                                <img src={star} alt='Star'/>
                            </this.Banner>
                            {localStorage.getItem('Words') !== null && wordsAndCategoriesInstanse.wordsAndCategories !== null ?
                                <this.CategoriesContainer>
                                    {wordsAndCategoriesInstanse.wordsAndCategories.words.map((item: any,index: any) => {
                                        return (
                                            <div>
                                                <p>{item.content}</p>
                                                <button onClick={() => this.handleDelete(index,false)}>
                                                    <i className='fa fa-minus-circle'/>
                                                </button>
                                            </div>
                                        )
                                    })}
                                </this.CategoriesContainer> : null
                            }
                        </> : null
                    }
                </this.Container>
            </>
        );
    }
}

export default Aboutus;

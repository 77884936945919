class Category {
    selectedCategory: number = 0;
    selectedPoint: number = 0;
    selectedCategories: Array<number> = [];
    goTo:string = '';
    blur: boolean = false;

    setCategory (number: number) {
        this.selectedCategory = number;
    }

    setPoint (number: number) {
        this.selectedPoint = number;
    }

    handleSelectedCategories (id: number) {
        if(this.selectedCategories.includes(id)) {
            var index = this.selectedCategories.indexOf(id);
            this.selectedCategories.splice(index, 1);
        } else {
            this.selectedCategories.push(id);
        }
    }

    setGoTo (route: string) {
        this.goTo = route;
    }



    changeBlur (state: boolean) {
        this.blur = state;
    }

}

export default Category;
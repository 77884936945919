import Team from "./team";
import getdb from "../database/getdb";
import { category, configNormalInstance , teamsInstance, gamesHistoryInstanse, gameNormalInstance} from "./index";
import Config_Normal from "./config_normal";
import Teams from "./teams";

class Game_Normal {
  historyGameId: number = 0;
  isComingFromHistory: boolean = false;
  mode: string = 'normal';
  step: string = 'selectCategory';
  gameId: number = 0;
  teams: Team[];
  laps: number;
  thinkingTime: number = 0;

  roundStarted: boolean = false;
  roundFinished: boolean = false;
  gameFinished: boolean = false;
  turn: number = 1;
  time: number = 0;
  word: string = "";
  isWordChanged: boolean = false;
  errors: number = 0;
  currentLap: number = 1;
  interval: number = 0;
  thinkingTimeInterval: number = 0;
  winner: string = "";
  
  constructor() {
    this.teams = teamsInstance.teams;
    this.laps = configNormalInstance.laps;
  }

  configure(configurations: Config_Normal,teams: Teams) {
    this.teams = teams.teams;
    this.laps = configurations.laps;
    this.thinkingTime = configurations.thinkingTime;
    this.gameFinished = false;
    this.currentLap = 1;
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    if(games !== null) {
      this.gameId = games.length + 1;
    } else {
      this.gameId = 1;
    }
  }

  setIsComingFromHistory = (gameId: number) => {
    this.historyGameId = gameId;
    this.gameId = gameId;
    this.isComingFromHistory = true;
  }

  setDataFromHistory = () => {
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    games.map((item: any) => {
      if(item.gameId === this.historyGameId) {
        this.teams = item.teams;
        this.time = item.time;
        this.laps = item.laps;
        this.thinkingTime = configNormalInstance.thinkingTime;
        this.currentLap = item.currentLap;
        this.gameId = item.gameId;
        this.historyGameId = item.gameId;
        this.roundStarted = false;
        this.roundFinished = false;
        this.turn = item.turn;
        this.word = item.word;
        this.isWordChanged = item.isWordChanged;
        this.errors = item.errors;
        this.thinkingTimeInterval = item.thinkingTimeInterval;
        this.interval = item.interval;
        this.step = item.step;
      }
      return null;
    })
    this.isComingFromHistory = false;
    gamesHistoryInstanse.saveGames(gameNormalInstance);
  }

  private setRoundTime(selectedPoint: number) {
    if(selectedPoint === 20) {
      this.time = 120;
    } else {
      this.time = selectedPoint * 30 + 30;
    }
  }

  private setWord = (
    selectedCategory: number,
    selectedPoint: number
  ): Promise<any> => {
    let iWillSetWord = new Promise((resolve, reject) => {
      let result = getdb(Number(selectedCategory), Number(selectedPoint));
      result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));

      resolve(1);
    });

    return iWillSetWord;
  };

  changeWord(selectedCategory: number, selectedPoint: number) {
    this.setWord(selectedCategory, selectedPoint);
    this.isWordChanged = true;
  }

  initializeRound() {
    this.roundStarted = false;
    this.roundFinished = false;
    this.errors = 0;
    this.isWordChanged = false;
    this.time = 0;
    this.setWord(category.selectedCategory, category.selectedPoint);
    this.setRoundTime(category.selectedPoint);
    this.ThinkingTimeTimer(configNormalInstance.thinkingTime);
  }

  play() {
    this.step = 'playGame';
    clearInterval(this.thinkingTimeInterval);
    this.roundStarted = true;
    this.interval = setInterval(() => {
      if (this.time > 0) {
        this.time--;
        gamesHistoryInstanse.saveGames(gameNormalInstance);
      }
      if (this.time === 0) {
        this.end();
        gamesHistoryInstanse.saveGames(gameNormalInstance);
      }
    }, 1000);
    
  }

  errorHappend() {
    if (this.errors < 3) {
      this.errors++;

      if (this.errors === 3) {
        setTimeout(() => {
          this.end();
        }, 1000);
      }
    }
    gamesHistoryInstanse.saveGames(gameNormalInstance);
  }

  end() {
    this.roundFinished = true;
    clearInterval(this.interval);
  }

  private changeTurn() {
    if (this.teams.length > this.turn) {
      this.turn++;
      this.step = 'selectCategory';
    } else {
      this.turn = 1;
      this.step = 'selectCategory';
      if (this.currentLap === configNormalInstance.laps) {
        this.gameFinished = true;
        this.setWinner();
      } else {
        this.currentLap++;
      }
    }
    gamesHistoryInstanse.saveGames(gameNormalInstance);
  }

  handleRight(point: number, remainingTime: number, index: number) {
    let updatedTeams = [];

    for (let i = 0; i < this.teams.length; i++) {
      if (index !== i) {
        updatedTeams.push(this.teams[i]);
      } else {
        if (this.isWordChanged === true) {
          let updatedTeam = new Team(
            this.teams[i].name,

            this.teams[i].score +
              Number(point) -
              Math.floor(this.errors / 2) +
              Math.floor(Number(remainingTime) / 30) -
              1
          );
          updatedTeams.push(updatedTeam);
        } else {
          let updatedTeam = new Team(
            this.teams[i].name,

            this.teams[i].score +
              Number(point) -
              Math.floor(this.errors / 2) +
              Math.floor(Number(remainingTime) / 30)
          );
          updatedTeams.push(updatedTeam);
        }
      }
    }
    this.teams = updatedTeams;
    this.changeTurn();
  }

  handleFalse() {
    this.changeTurn();
  }

  private ThinkingTimeTimer(time: number) {
    this.thinkingTime = time;
    this.thinkingTimeInterval = setInterval(() => {
      if (this.thinkingTime > 0) {
        this.thinkingTime--;
        gamesHistoryInstanse.saveGames(gameNormalInstance);
      } else {
        this.play();
      }
    }, 1000);
  }

  setWinner() {
    let groupScores: any = [];
    this.teams.map((item: any) => {
      groupScores.push(item.score);
      return null;
    });
    const maxScore: number = Math.max(...groupScores);

    if (this.checkDuplicate(maxScore, groupScores) > 1) {
      this.winner = "بازی مساوی شد";
    } else {
      this.teams.map((item: any) => {
        if (item.score === maxScore) {
          this.winner = item.name + " برنده شد ";
        }
        return null;
      });
    }
  }

  private checkDuplicate(maxScore: number, groupScores: any) {
    let duplicate: number = 0;

    for (let i = 0; i < this.teams.length; i++) {
      if (groupScores[i] === maxScore) {
        duplicate++;
      }
    }

    return duplicate;
  }
}

export default Game_Normal;

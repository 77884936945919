import Game_Normal from "./game_normal";
import Config_Normal from "./config_normal";
import Category from "./category";
import Config_Quick from "./config_quick";
import Game_Quick from "./game_quick";
import Config_Fight from "./config_fight";
import Game_Fight from "./game_fight";
import Config_Triple from "./config_triple";
import Game_Triple from "./game_triple";
import Teams from './teams';
import Games_History from './games_history';
import Words from './words';

export const teamsInstance = new Teams();

export const configNormalInstance = new Config_Normal();

export const gameNormalInstance = new Game_Normal();

export const category = new Category();

export const configQuickInstance = new Config_Quick();

export const gameQuickInstance = new Game_Quick();

export const configFightInstance = new Config_Fight();

export const gameFightInstance = new Game_Fight();

export const configTripleInstance = new Config_Triple();

export const gameTripleInstance = new Game_Triple();

export const gamesHistoryInstanse = new Games_History();

export const wordsAndCategoriesInstanse = new Words();
import Team from './team';

class Teams {
    teams: Team[] = [];
    funnyNames: Array<string> = [
        'بزرگتر ها',
        'دختر های فامیل بجز نسرین',
        'پسر های فامیل',
        'کدو تنبل',
        'مو سفید ها'
    ];
    usedFunnyNames: Array<string> = [];
  
    initializeTeams () {
        if(localStorage.getItem('Teams') !== null) {    
            let localTeams: any = localStorage.getItem('Teams');
            this.teams = JSON.parse(localTeams);
            let newFunnyNames = this.funnyNames;
            this.funnyNames = [];
            this.teams.map((item: any) => {
                this.usedFunnyNames.push(item.name);
                return null;
            })
            let diff = (A: Array<any>, B: Array<any>) => {
                return A.filter(function (a) {
                    return B.indexOf(a) === -1;
                });
            }
            this.funnyNames = diff(newFunnyNames,this.usedFunnyNames);
        }
        else {
            for(let i = 0; i < 2; i++) {
                this.addTeams();
            }
        }
    }

    addTeams() {
        if (this.teams.length < 4) {
            let randomNumber = Math.floor(Math.random() * this.funnyNames.length);
            this.teams.push(new Team(this.funnyNames[randomNumber],0))
            this.usedFunnyNames.push(this.funnyNames[randomNumber]);
            this.funnyNames.splice(randomNumber,1);
        }
    }
    
    removeTeams() {
        if (this.teams.length > 2) {
            this.funnyNames.push(this.teams[this.teams.length-1].name);
            this.usedFunnyNames.map((item: any, index: number) => {
                if(item === this.teams[this.teams.length-1].name) {
                    this.usedFunnyNames.splice(index,1);
                }
                return null;
            })
            this.teams.splice(-1, 1);
        }
    }
    
    editTeams(id: number, newValue: string) {
        this.teams[id].name = newValue;
    }
}
  
  export default Teams;
import React, { Component } from "react";
import styled from "styled-components";
import '../css/round.css';
import star from '../assets/img/star.png';
import { gameQuickInstance } from '../game';


interface IRoundPageState {
  myInterval: any;
}

const initialState: IRoundPageState = {
  myInterval: undefined
};

interface IRoundPageProps {
  history: any;
}

class Round extends Component<IRoundPageProps,IRoundPageState> {

  constructor(props: IRoundPageProps) {
    super(props);
    this.state = initialState;
  }

  Container = styled.div`
    max-width: 40rem;
    padding: 7rem 0 1rem;
    width: 95vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `;

  Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  TopButtons = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0;
  `;

  ControlButtons = styled.div`
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: 2rem;

    & p {
      font-size: .8rem;
      margin-top: .5rem
    }
  `;

  Word = styled.p`
    width: 100%;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #333;
  `;

  Button = styled.button`
    width: 4rem;
    height: 4rem;
    background-Color: #fff;
    border-Radius: 2rem;
    display: flex;
    align-Items: center;
    justify-Content: center;
    border: none;
    outline: none;
    color: #333;
    position: relative;

    & div {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      left: 0%;
      top: 0%;
      background-color: #E53935;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  FinishingButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  `;

  thinkingTimeBox = styled.div`
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    border-radius: 50%;
    font-size: 2rem;
  `;

  Banner = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #fff;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.3rem;
  margin: 1.5rem 0 0 0;
  `;

  componentDidMount() {
    if(gameQuickInstance.isComingFromHistory) {
      gameQuickInstance.setDataFromHistory();
    } else {
      gameQuickInstance.initializeGame();
      gameQuickInstance.initializeRound();
    }
    this.forceUpdate();
  }
  
  handlePlay = () => {
    gameQuickInstance.play();
    // this interval for timer
    let interval = setInterval(() => {
      this.forceUpdate();
      if(gameQuickInstance.gameFinished) {
        clearInterval(interval)
        this.props.history.push('/game_quick_result');
      }
    },100)
  }

  handleFinished = (state: boolean) => {
    gameQuickInstance.handleAnswer(gameQuickInstance.turn,state);
  }

  render() {
    return (
      <this.Container>
        <this.Banner>
          <img src={star} alt='Star'/>
          <p>نوبت {gameQuickInstance.teams[gameQuickInstance.turn-1].name}</p>
          <img src={star} alt='Star'/>
        </this.Banner>
        <this.Word>{gameQuickInstance.word}</this.Word>
        <this.Buttons>
          {!gameQuickInstance.started ?
          <this.ControlButtons>
            <this.Button onClick={() => this.handlePlay()}>
              <i className="fa fa-play" style={{fontSize: '1.5rem'}}/>
            </this.Button>
            <p>شروع</p>
          </this.ControlButtons> : undefined}
          <this.TopButtons>
            <div className='timer' style={gameQuickInstance.started ? {animationName: 'timer'} : undefined}>{gameQuickInstance.time}</div>
          </this.TopButtons>
          {gameQuickInstance.started ?
          <this.FinishingButtons>
            <this.Button onClick={() => this.handleFinished(true)}>
              <i className="fa fa-check" style={{color: '#388E3C',fontSize: '1.5rem'}}/>
            </this.Button>
            <this.Button onClick={() => this.handleFinished(false)}>
              <i className="fa fa-times" style={{color: '#E53935',fontSize: '1.5rem'}}/>
            </this.Button>
        </this.FinishingButtons> : null}
        </this.Buttons>
      </this.Container>
    );
  }
}

export default Round;

import { createGlobalStyle } from 'styled-components';

import VazirEot from './Vazir.eot';
import VazirEot2 from './Vazir.eot?#iefix';
import VazirWoff from './Vazir.woff';
import VazirWoff2 from './Vazir.woff2';
import VazirTtf from './Vazir.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Vazir';
        src: local('Vazir'), local('Vazir'),
        url(${VazirWoff2}) format('woff2'),
        url(${VazirWoff}) format('woff'),
        url(${VazirEot}) format('eot'),
        url(${VazirEot2}) format('eot'),
        url(${VazirTtf}) format('ttf');
        font-weight: 400;
        font-style: normal;
    }
`;
class Config_Triple {
    roundTime: number = 30;
    difficulty: number = 1;
    Words: number = 20;
    selectingCategories = 'auto';

    changeGameTimeValue (increase: boolean) {
        switch (increase) {
            case true:
                if (this.roundTime < 120) {
                    this.roundTime += 10;
                }
                break;
            case false:
                if (this.roundTime > 30) {
                    this.roundTime -= 10;
                }
                break;
            default:
        }   
    }

    changeGameWordsValue (increase: boolean) {
        switch (increase) {
            case true:
                if (this.Words < 60) {
                    this.Words += 1;
                }
                break;
            case false:
                if (this.Words > 20) {
                    this.Words -= 1;
                }
                break;
            default:
        }   
    }

    changeDifficultyValue (difficulty: number) {
        this.difficulty = difficulty;
    }

    changeSelectingCategoriesValue (value: string) {
        this.selectingCategories = value;
    }
}

export default Config_Triple;
import {teamsInstance} from "./";

class Config_Fight {
  laps_time: number = teamsInstance.teams.length * 60;
  difficulty: number = 1;
  selectingCategories = 'auto';

  changeLapsTimeValue(increase: boolean) {
    switch (increase) {
      case true:
        if (this.laps_time < teamsInstance.teams.length * 120) {
          this.laps_time += 10;
        }
        break;
      case false:
        if (this.laps_time > teamsInstance.teams.length * 60) {
          this.laps_time -= 10;
        }
        break;
      default:
    }
  }

  setLapsTimeByTeamsLength() {
    this.laps_time = teamsInstance.teams.length * 60;
  }

  changeDifficultyValue (difficulty: number) {
    this.difficulty = difficulty;
  }

  changeSelectingCategoriesValue (value: string) {
    this.selectingCategories = value;
  }
}

export default Config_Fight;

import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import categories from '../database/categories.json';
import {category} from '../game';

interface ICategoryPageState {
}

const initialState: ICategoryPageState = {
};

interface ICategoryPageProps {
    history: any
}

class Category extends Component<ICategoryPageProps,ICategoryPageState> {

    Container = styled.div`
        max-width: 40rem;
        padding: 6rem .8rem 2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        position: relative;
    `;

    Banner = styled.div`
    width: 100%;
    height: 3rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3rem;
    margin: 1.5rem 0 .5rem;
    `;

    CategoryItem = styled.div`
        width: 48%;
        height: 8rem;
        background-color: #fff;
        border-radius: 2rem;
        margin: .2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #555;

        & i {
            font-size: 2.4rem;
        }

        & p {
            font-size: 1rem;
            margin-top: -1rem;
        }
    `;

    constructor(props: ICategoryPageProps) {
        super(props);
        this.state = initialState;
    }

    handleClick = (id: number) => {
        category.changeBlur(true);
        category.setCategory(id);
        this.forceUpdate();
    }

    handleClickMagic = (id: number) => {
        category.setCategory(id);
        category.setPoint(20);
        this.forceUpdate();
        this.props.history.push('/round_normal');
    }

    render() {
        return (
            <>
                <Backpage to='gameresult'/>
                <this.Container>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>انتخاب دسته بندی</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    {categories.RECORDS.map((item: any,index: number) => {
                        return (
                            <this.CategoryItem style={item.Img === "fa fa-magic" ? {backgroundColor: "#fdd835"} : undefined} key={index} onClick={item.Img !== 'fa fa-magic' ? () => this.handleClick(Number(item.ID)) : () => this.handleClickMagic(Number(item.ID))}>
                                <i className={item.Img}/>
                                <p>{item.CategoryName}</p>
                            </this.CategoryItem>
                        )
                    })}
                </this.Container>
            </>
        );
    }
}

export default Category;
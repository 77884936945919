import Team from "./team";
import getdb from "../database/getdb";
import { configTripleInstance,teamsInstance,gamesHistoryInstanse, gameTripleInstance } from "./index";
import Config_Triple from "./config_triple";
import Teams from "./teams";

class Game_Triple {
  historyGameId: number = 0;
  isComingFromHistory: boolean = false;
  mode: string = 'triple';
  gameId: number = 0;
  teams: Team[];
  laps: Array<string> = ['اول : شرح دادن','دوم : پانتومیم','سوم : توضیح با یک کلمه'];
  currentLap: number = 1;
  selectedCategories: Array<number> = [];
  roundStarted: boolean = false;
  roundFinished: boolean = false;
  gameFinished: boolean = false;
  turn: number = 1;
  time: number = 0;
  word: string = '';
  words: Array<string> = [];
  spokenWords: Array<string> = [];
  unspokenwords: Array<string> = [];
  answers: number = 0;
  interval: any = 0;
  winner: string = "";
  
  constructor() {
    this.teams = teamsInstance.teams;
    this.time = configTripleInstance.roundTime;
  }

  configure(configurations: Config_Triple,teams: Teams) {
    this.teams = teams.teams;
    this.time = configurations.roundTime;
    this.gameFinished = false;
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    if(games !== null) {
      this.gameId = games.length + 1;
    } else {
      this.gameId = 1;
    }
  }

  setIsComingFromHistory = (gameId: number) => {
    this.historyGameId = gameId;
    this.gameId = gameId;
    this.isComingFromHistory = true;
  }

  setDataFromHistory = () => {
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    games.map((item: any) => {
      if(item.gameId === this.historyGameId) {
        this.teams = item.teams;
        this.laps = item.laps;
        this.currentLap = item.currentLap;
        this.roundStarted = false;
        this.roundFinished = false;
        this.turn = item.turn;
        if(item.time === 0) {
          this.time = configTripleInstance.roundTime;
        } else {
          this.time = item.time;
        }
        this.word = item.word;
        this.words = item.words;
        this.spokenWords = item.spokenWords;
        this.unspokenwords = item.unspokenwords;
        this.answers = item.answers;
        this.gameId = item.gameId;
        this.historyGameId = item.gameId;
        this.interval = item.interval;
      }
      return null;
    })
    gamesHistoryInstanse.saveGames(gameTripleInstance);
  }

  private setWord = (): Promise<any> => {
    let iWillSetWord = new Promise((resolve, reject) => {
      if(this.selectedCategories.length > 0) {
        let randomCategory = Math.floor(Math.random()*this.selectedCategories.length+1);
        let result = getdb(Number(this.selectedCategories[randomCategory-1]), Number(configTripleInstance.difficulty));
        result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));
        resolve(1);
      }
      else {
        let randomCategory: number = Math.floor(Math.random()*20+1);
        let result = getdb(Number(randomCategory), Number(configTripleInstance.difficulty));
        result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));
        resolve(1);
      }
    });
    return iWillSetWord;
  };

  initializeRound() {
    this.roundStarted = false;
    this.roundFinished = false;
    this.time = configTripleInstance.roundTime;
  }

  play() {
    this.roundStarted = true;
    this.roundFinished = false;
    if (this.currentLap > 1) {
      this.word = this.words[this.words.length - 1];
      if(this.unspokenwords.length > 0) {
        this.word = this.unspokenwords[this.unspokenwords.length - 1];
      }
    }
    else if (this.words.length < configTripleInstance.Words) {
      this.setWord();
    }
    this.interval = setInterval(() => {
      if (this.time > 0) {
        this.time--;
        gamesHistoryInstanse.saveGames(gameTripleInstance);
      }
      if (this.time === 0) {
        this.end();
      }
    }, 1000);
    gamesHistoryInstanse.saveGames(gameTripleInstance);
  }

  end() {
    this.roundFinished = true;
    this.answers = 0;
    this.changeTurn();
    clearInterval(this.interval);
    gamesHistoryInstanse.saveGames(gameTripleInstance);
  }

  private changeTurn() {
    if (this.teams.length > this.turn) {
      this.turn++;
    } 
    else if(this.spokenWords.length < configTripleInstance.Words) {
      this.turn = 1;
    } else {
      if (this.currentLap === 3) {
        this.gameFinished = true;
        this.setWinner();
      } else {
        this.turn = 1;
      }
    }
    this.roundStarted = false;
    gamesHistoryInstanse.saveGames(gameTripleInstance);
  }

  updatePoint(point: number,index: number) {
    let updatedTeams = [];

    for (let i = 0; i < this.teams.length; i++) {
      if (index !== i) {
        updatedTeams.push(this.teams[i]);
      } 
      else {
          let updatedTeam = new Team(
            this.teams[i].name,
            this.teams[i].score +
              Number(point));
          updatedTeams.push(updatedTeam);
      }
    }
    this.teams = updatedTeams;
    gamesHistoryInstanse.saveGames(gameTripleInstance);
  }

  handleAnswer (isCorrect: boolean) {
    this.answers++;
    if(this.currentLap > 1) {
      if(isCorrect) {
        if(this.words.length === 0 && this.unspokenwords.length > 0) {
          this.updatePoint(1,this.turn-1);
          this.spokenWords.push(this.unspokenwords[this.unspokenwords.length - 1]);
          this.unspokenwords.splice(-1,1);
          this.word = this.unspokenwords[this.unspokenwords.length - 1];
          if(this.answers === (this.unspokenwords.length + this.spokenWords.length)) {
            this.end();
          }
        }
        else {
          this.updatePoint(1,this.turn-1);
          this.spokenWords.push(this.words[this.words.length - 1]);
          this.words.splice(-1,1);
          this.word = this.words[this.words.length - 1];
        }
        if(this.currentLap === 3 && this.spokenWords.length === configTripleInstance.Words) {
          this.setWinner();
          this.gameFinished = true;
        }
        if(this.spokenWords.length === configTripleInstance.Words) {
          this.currentLap++;
          this.turn = 1;
          this.roundStarted = false;
          this.roundFinished = true;
          this.words = this.spokenWords;
          this.spokenWords = [];
          this.unspokenwords = [];
        }
      }
      else {
        if(this.words.length === 0 && this.unspokenwords.length > 0) {
          this.unspokenwords = [
            this.unspokenwords[this.unspokenwords.length - 1],
            ...this.unspokenwords
          ]
          this.unspokenwords.splice(-1, 1);
          this.word = this.unspokenwords[this.unspokenwords.length - 1];
          if(this.answers === (this.unspokenwords.length + this.spokenWords.length) || this.answers === this.unspokenwords.length) {
            this.end();
          }
        }
        else {
          this.unspokenwords.push(this.words[this.words.length - 1]);
          this.words.splice(-1,1);
          this.word = this.words[this.words.length - 1];  
        }
      }
    }
    // if answers is correct ------------------------------------------
    else if(isCorrect) {
      // if we do not have enough words ------------------------------------------
      if (this.words.length < configTripleInstance.Words) {
        this.updatePoint(1,this.turn-1);
        this.words.push(this.word);
        this.spokenWords.push(this.word);
        if (this.words.length < configTripleInstance.Words) {
          this.setWord();
        }
      }
      // if answer is correct and we have unspoken words
      else if (this.unspokenwords.length > 0) {
        this.updatePoint(1,this.turn-1);
        this.spokenWords.push(this.unspokenwords[this.unspokenwords.length-1]);
        this.unspokenwords.splice(-1, 1);
        // if unspoken words finished 
        if (this.unspokenwords.length === 0) {
          this.end();
        }
        // else we stil have unspoken words
        else {
          this.word = this.unspokenwords[this.unspokenwords.length-1];
        }
      }
      if(this.spokenWords.length === configTripleInstance.Words) {
        this.turn = 1;
        this.currentLap++;
        this.roundFinished = true;
        this.unspokenwords = [];
        this.spokenWords = [];
      }
    }
    // answer is not correct and we have unspoken words
    else if (this.unspokenwords.length > 0 && this.words.length === configTripleInstance.Words) {        
      this.unspokenwords = [
        this.unspokenwords[this.unspokenwords.length - 1],
        ...this.unspokenwords
      ]
      this.unspokenwords.splice(-1, 1);
      this.word = this.unspokenwords[this.unspokenwords.length - 1];
      if(this.answers === (this.unspokenwords.length + this.spokenWords.length) - 1 || (this.words.length === configTripleInstance.Words && this.answers === this.unspokenwords.length)) {
        this.end();
      }
    }
    else {
      this.words.push(this.word);
      this.unspokenwords.push(this.word);
      if (this.words.length < configTripleInstance.Words) {
        this.setWord();
      }
    }
    if (this.answers === configTripleInstance.Words){
      this.end();
    }
    gamesHistoryInstanse.saveGames(gameTripleInstance);
  }

  setWinner() {
    let groupScores: any = [];
    this.teams.map((item: any) => {
      groupScores.push(item.score);
      return null;
    });
    const maxScore: number = Math.max(...groupScores);

    if (this.checkDuplicate(maxScore, groupScores) > 1) {
      this.winner = "بازی مساوی شد";
    } else {
      this.teams.map((item: any) => {
        if (item.score === maxScore) {
          this.winner = item.name + " برنده شد ";
        }
        return null;
      });
    }
  }

  private checkDuplicate(maxScore: number, groupScores: any) {
    let duplicate: number = 0;

    for (let i = 0; i < this.teams.length; i++) {
      if (groupScores[i] === maxScore) {
        duplicate++;
      }
    }

    return duplicate;
  }
}

export default Game_Triple;

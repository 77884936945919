import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import telegram from '../assets/img/telegram.png';
import whatsapp from '../assets/img/whatsapp.png';
import twitter from '../assets/img/twitter.png';
import copy from '../assets/img/copy.png';

class Share extends Component {
    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 8rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Banner = styled.div`
        width: 100%;
        height: 3rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1rem;
        margin: .5rem 0;
    `;

    ButtonsContainer = styled.div`
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border-radius: 2rem;

        & a {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        & img {
            width: 2.5rem;
            height: 2.5rem;
        }

        & button {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-around;

            &:focus {
                outline: none;
            }
        }
    `;

    copyToClipBoard = async (copyMe: any) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            alert('لینک کپی شد.')
        }
        catch (err) {
            throw err;
        }
    };

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>معرفی به دیگران</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.ButtonsContainer>
                        <a href='https://t.me/share/url?url=https://adabazy.ir/&text=ادابازی - با ادابازی لحظاتی خوش را کنار دوستان و خانواده داشته باشید'>
                            <img src={telegram} alt='Star'/>
                        </a>
                        <a href='https://wa.me/?text=https://adabazy.ir'>
                            <img src={whatsapp} alt='Star'/>
                        </a>
                        <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" data-show-count="false">
                            <img src={twitter} alt='Star'/>
                            <script async src="https://platform.twitter.com/widgets.js"></script>
                        </a>
                        <button onClick={() => this.copyToClipBoard('https://adabazy.ir/')}>
                            <img src={copy} alt='Star'/>
                        </button>
                    </this.ButtonsContainer>
                </this.Container>
            </>
        );
    }
}

export default Share;

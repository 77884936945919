import React, { Component } from 'react';
import Backpage from '../components/backpage';
import styled from 'styled-components';

class Rules extends Component {

    state = {
        selectedRules: 'normal'
    }

    normal_rules: Array<string> = [
        'اشاره کردن به اشیاء و لب زدن خطا محسوب میشود',
        'اگر اجراکننده تیم در حین اجرا مرتکب 3 خطا شود تیم وی در آن دور بازنده خواهد شد',
        'به ازای هر 30 ثانیه زمان اضافه 1 امتیاز به تیم اجرا کننده میرسد',
        'عوض کردن کلمه قبل از شروع بازی دارای 1 امتیاز منفی است'
    ];

    quick_rules: Array<string> = [
        'اشاره کردن به اشیاء و لب زدن خطا محسوب میشود',
        'اگر اجرا کننده مرتکب خطا شود تیم او در آن دور امتیازی نخواهد گرفت'
    ];

    fight_rules: Array<string> = [
        'اشاره کردن به اشیاء و لب زدن خطا محسوب میشود',
        'تیمی که بعد از اتمام زمان دور نوبت ان باشد از بازی حذف میشود'
    ];

    triple_rules: Array<string> = [
        'اشاره کردن به اشیاء و لب زدن خطا محسوب میشود',
        'بازی شامل سه دور متفاوت است : شرح دادن - پانتومیم - توضیح با یک کلمه',
        'شرح دادن : کلمه را بدون اشاره مستقیم به ان توضیح دهید',
        'پانتومیم : کلمه را بازی کنید',
        'توضیح با یک کلمه : کلمه را تنها با یک کلمه و بدون اشاره مستقیم به آن بگویید',
        'هر دور وقتی به پایان میرسد که تمامی کلمات مشخص شده را گفته باشید'
    ];


    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 7rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Item = styled.div`
        width: 100%;
        border-radius: 2rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .5rem;
        margin: .5rem 0;

        & span { 
            width: 95%;
            display: flex;
            align-items: center;
            margin: .5rem 0;

            & i {
                color: #fcb400;
                margin: .3rem;
            }

            & p {
                margin-right: .1rem;
                text-align: right;
            }
        }
    `;

    BtnContainer = styled.div`
        width: 100%;
        height: 3.5rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        & button {
            width: 20%;
            height: 99%;
            border: none;
            background-color: transparent;
            color: #333;
            font-family: inherit;
            font-size: 1rem;
            border-bottom: .2rem solid transparent;
            transition: all .2s;

            &:focus {
                outline: none;
            }
        }
    `;

    handleRulse = (rules: string) => {
        this.setState({
            selectedRules: rules
        })
    }

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.BtnContainer>
                        <button style={this.state.selectedRules === 'normal' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handleRulse('normal')}>معمولی</button>
                        <button style={this.state.selectedRules === 'quick' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handleRulse('quick')}>سرعتی</button>
                        <button style={this.state.selectedRules === 'fight' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handleRulse('fight')}>مبارزه ای</button>
                        <button style={this.state.selectedRules === 'triple' ? {borderBottom: '.2rem solid red'} : undefined} onClick={() => this.handleRulse('triple')}>سه گانه</button>
                    </this.BtnContainer>
                    {this.state.selectedRules === 'normal' ?
                        this.normal_rules.map((rule,index) => {
                            return (
                                <this.Item key={index}>
                                    <span>
                                        <i className="fas fa-dot-circle"/>
                                        <p>{rule}</p>
                                    </span>
                                </this.Item>
                            )
                        }) : this.state.selectedRules === 'quick' ? 
                        this.quick_rules.map((rule,index) => {
                            return (
                                <this.Item key={index}>
                                    <span>
                                        <i className="fas fa-dot-circle"/>
                                        <p>{rule}</p>
                                    </span>
                                </this.Item>
                            )
                        })
                    : this.state.selectedRules === 'fight' ?
                    this.fight_rules.map((rule,index) => {
                        return (
                            <this.Item key={index}>
                                <span>
                                    <i className="fas fa-dot-circle"/>
                                    <p>{rule}</p>
                                </span>
                            </this.Item>
                        )
                    })
                    : this.state.selectedRules === 'triple' ?
                    this.triple_rules.map((rule,index) => {
                        return (
                            <this.Item key={index}>
                                <span>
                                    <i className="fas fa-dot-circle"/>
                                    <p>{rule}</p>
                                </span>
                            </this.Item>
                        )
                    })
                : null}
                </this.Container>
            </>
        );
    }
}

export default Rules;

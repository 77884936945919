import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface IBackpageComponentState {
}

interface IBackpageComponentProps {
    to: any;
}

class Backpage extends Component<IBackpageComponentProps,IBackpageComponentState> {
    render() {
        return (
            <Link style={{
                backgroundColor: 'transparent',
                border: 'none',
                position: 'absolute',
                left: '7%',
                top: '4%',
                fontSize: '2rem',
                color: 'rgb(250, 250, 250)',
            }} to={this.props.to}>
                <i className="fas fa-caret-left"/>
            </Link>
        );
    }
}

export default Backpage;

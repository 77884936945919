import React, { Component } from 'react';
import styled from 'styled-components';
import {category} from '../game';
import {Link} from 'react-router-dom';

interface IPopupPageState {
    rendered: boolean
}

const initialState: IPopupPageState = {
    rendered: false
};

interface IPopupPageProps {
}

class Popup extends Component<IPopupPageProps,IPopupPageState> {

    constructor(props: IPopupPageProps) {
        super(props);
        this.state = initialState;
    }

    Container = styled.div `
        width: 100%;
        height: 150vh;
        position: fixed;
        z-index: 5;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: rgba(100,100,100,.5);
        transform: translateY(30%);
        transition: all .2s;
    `;

    Scores = styled.div`
        width: 100%;
        background-color: #333;
        box-shadow: 0 .5rem .5rem #999;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        & p {
            color: #eee;
            
        }

        & a:last-child i {
                height: 0;
            }
    `;

    Header = styled.div`
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #eee;
        font-size: 1.3rem;
        margin: .5rem 0;

        & i {
            font-size: 1rem
        }
    `;

    Score = styled.div`
        width: 100%;
        height: 3rem;
        color: #fff;
        text-decoration: none;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        & i {
            width: 95%;
            background: linear-gradient(45deg, rgba(51,51,51,1) 0%, rgba(238,238,238,1) 20%, rgba(238,238,238,1) 50%, rgba(238,238,238,1) 80%, rgba(51,51,51,1) 100%);
            height: 1px;
        }

        & div {
            margin: auto;
        }

        &:last-of-type i {
            width: 0;
            height: 0;
        }

        & a {
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
        }
    `;

    Click = styled.div`
        width: 100%;
        height: 100%;
    `;

    handleClickBlur = () => {
        category.changeBlur(false);
        this.forceUpdate();
    }

    handlePoint = (point: number) => {
        category.setPoint(point);
        category.changeBlur(false);
        this.forceUpdate();
    }

    componentDidMount(){
        setTimeout(() =>{
            this.setState({
                rendered: true
            })
        },0)
    }

    render() {
        return (
                <this.Container style={this.state.rendered ? {transform: 'translate(0)'} : undefined}>
                    <this.Click onClick={() => this.handleClickBlur()}/>
                    <this.Scores>
                        <this.Header>
                            <p>انتخاب امتیاز</p>
                            <i onClick={() => this.handleClickBlur()} className='fa fa-chevron-down'/>
                        </this.Header>
                        <this.Score onClick={() => this.handlePoint(1)}>
                            <Link to='/round_normal'></Link>
                            <div>1</div>
                            <i></i>
                        </this.Score>
                        <this.Score onClick={() => this.handlePoint(2)}>
                            <Link to='/round_normal'></Link>
                            <div>2</div>
                            <i></i>
                        </this.Score>
                        <this.Score onClick={() => this.handlePoint(3)}>
                            <Link to='/round_normal'></Link>
                            <div>3</div>
                            <i></i>
                        </this.Score>
                    </this.Scores>
                </this.Container>
        );
    }
}

export default Popup;

import React from "react";
import "./App.css";
import "./Fontawesome/css/fontawesome.css";
import "./Fontawesome/css/solid.css";
import GlobalFonts from "./assets/font/fonts";
import Homepage from "./pages/homepage";
import Rules from "./pages/rules";
import History from "./pages/history";
import Mainsound from "./components/mainSound";
import Modes from "./pages/modes";
import Setting_Normal from "./pages/setting_normal";
import Setting_Quick from "./pages/setting_quick";
import Setting_Fight from "./pages/setting_fight";
import Setting_Triple from "./pages/setting_triple";
import Game_Normal_Result from "./pages/game_normal_result";
import Game_Quick_Result from "./pages/game_quick_result";
import Game_Fight_Result from "./pages/game_fight_result";
import Game_Triple_Result from "./pages/game_triple_result";
import Category from "./pages/category";
import MultipleCategory from "./pages/multiple_category";
import Popup from "./components/popup";
import AboutUs from "./pages/about-us";
import Share from "./pages/share";
import Words from "./pages/words";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Round_Normal from "./pages/round_normal";
import Round_Quick from "./pages/round_quick";
import Round_Fight from "./pages/round_fight";
import Round_Triple from "./pages/round_triple";
import { category } from "./game";

class App extends React.Component {
  componentDidMount() {
    setInterval(() => {
      this.forceUpdate();
    }, 100);
  }

  render() {
    return (
      <div className="App">
        <div className={!false ? "AppError" : "AppError Active"}  id="App"></div>
        <GlobalFonts />
        <Router>
          <div
            style={
              false
                ? { filter: "blur(10px)", transition: "all .2s" }
                : undefined
            }
          >
            <Mainsound />
            <Switch>
              <Route path="/" exact={true} component={Homepage} />
              <Route path="/rules" component={Rules} />
              <Route path="/history" component={History} />
              <Route path="/modes" component={Modes} />
              <Route path="/setting_normal" component={Setting_Normal} />
              <Route path="/setting_quick" component={Setting_Quick} />
              <Route path="/setting_fight" component={Setting_Fight} />
              <Route path="/setting_triple" component={Setting_Triple} />
              <Route path="/game_normal_result" component={Game_Normal_Result} />
              <Route path="/game_quick_result" component={Game_Quick_Result} />
              <Route path="/game_fight_result" component={Game_Fight_Result} />
              <Route path="/game_triple_result" component={Game_Triple_Result} />
              <Route path="/category" component={Category} />
              <Route path="/multiple_category" component={MultipleCategory} />
              <Route path="/round_normal" component={Round_Normal} />
              <Route path="/round_quick" component={Round_Quick} />
              <Route path="/round_fight" component={Round_Fight} />
              <Route path="/round_triple" component={Round_Triple} />
              <Route path="/about_us" component={AboutUs} />
              <Route path="/share" component={Share} />
              <Route path="/words" component={Words} />
              <Route path="*" component={Homepage} />
            </Switch>
          </div>
          {category.blur ? <Popup /> : null}
        </Router>
      </div>
    );
  }
}

export default App;

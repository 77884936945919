import Team from "./team";
import getdb from "../database/getdb";
import { configFightInstance, teamsInstance, gamesHistoryInstanse, gameFightInstance, category } from "./index";
import Config_Fight from "./config_fight";
import Teams from "./teams";

class Game_Fight {
  historyGameId: number = 0;
  isComingFromHistory: boolean = false;
  mode: string = 'fight';
  gameId: number = 0;
  teams: Team[];
  laps: number;
  lapsTime: number;
  selectedCategories: Array<number> = [];
  roundStarted: boolean = false;
  roundFinished: boolean = false;
  gameFinished: boolean = false;
  turn: number = 1;
  word: string = "";
  currentLap: number = 1;
  interval: any = 0;
  winner: string = "";
  
  constructor() {
    this.teams = teamsInstance.teams;
    this.laps =  teamsInstance.teams.length;
    this.lapsTime = configFightInstance.laps_time;
    this.selectedCategories = category.selectedCategories;
  }

  configure(configurations: Config_Fight,teams: Teams) {
    this.teams = teams.teams;
    this.gameFinished = false;
    this.lapsTime = configFightInstance.laps_time;
    this.currentLap = 1;
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    if(games !== null) {
      this.gameId = games.length + 1;
    } else {
      this.gameId = 1;
    }
  }

  setIsComingFromHistory = (gameId: number) => {
    this.historyGameId = gameId;
    this.gameId = gameId;
    this.isComingFromHistory = true;
  }

  setDataFromHistory = () => {
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    games.map((item: any) => {
      if(item.gameId === this.historyGameId) {
        this.teams = item.teams;
        if(item.lapsTime === 0) {
          this.lapsTime = configFightInstance.laps_time;
        } else {
          this.lapsTime = item.lapsTime;
        }
        this.currentLap = item.currentLap;
        this.gameId = item.gameId;
        this.historyGameId = item.gameId;
        this.laps = item.laps;
        this.roundStarted = false;
        this.roundFinished = false;
        this.turn = item.turn;
        this.word = item.word;
        this.interval = item.interval;
      }
      return null;
    })
    gamesHistoryInstanse.saveGames(gameFightInstance);
  }

  setWord = (): Promise<any> => {
    let iWillSetWord = new Promise((resolve, reject) => {
      if(this.selectedCategories.length > 0) {
        let randomCategory = Math.floor(Math.random()*this.selectedCategories.length+1);
        let result = getdb(Number(this.selectedCategories[randomCategory-1]), Number(configFightInstance.difficulty));
        result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));
        resolve(1);
      }
      else {
        let randomCategory: number = Math.floor(Math.random()*20+1);
        let result = getdb(Number(randomCategory), Number(configFightInstance.difficulty));
        result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));
        resolve(1);
      }
    });
    return iWillSetWord;
  };

  initializeRound() {
    this.roundStarted = false;
    this.roundFinished = false;
    this.gameFinished = false;
    this.lapsTime = configFightInstance.laps_time;
  }

  play() {
    this.roundStarted = true;
    if(this.isComingFromHistory === false) {
      this.setWord();
    }
    this.interval = setInterval(() => {
      if (this.lapsTime > 0) {
        this.lapsTime--;
        gamesHistoryInstanse.saveGames(gameFightInstance);
      }
      if (this.lapsTime === 0) {
        this.roundFinish();
        gamesHistoryInstanse.saveGames(gameFightInstance);
      }
    }, 1000);
  }

  roundFinish() {
    this.isComingFromHistory = false;
    this.roundFinished = true;
    this.handlePoint(1,this.turn-1);
    this.setWinner();
    this.laps =  teamsInstance.teams.length - 1;
    if (this.currentLap === this.laps) {
      this.gameFinished = true;
    } else {
      this.currentLap++;
    }
    this.turn = 1;
    clearInterval(this.interval);
    gamesHistoryInstanse.saveGames(gameFightInstance);
  }

  changeTurn() {
    if (this.teams.length > this.turn) {
      this.turn++;
    } else {
      this.turn = 1;
    }
    gamesHistoryInstanse.saveGames(gameFightInstance);
  }

  handlePoint(point: number, index: number) {
    let updatedTeams = [];

    for (let i = 0; i < this.teams.length; i++) {
      if (index !== i) {
        updatedTeams.push(this.teams[i]);
      }
      else {
        let updatedTeam = new Team(
          this.teams[i].name,
          this.teams[i].score + Number(point)
        );
        updatedTeams.push(updatedTeam);
      }
    }
    this.teams = updatedTeams;
  }

  setWinner() {
    // let groupScores: any = [];
    let updatedTeams = [];
    for (let i = 0; i < this.teams.length; i++) {
      if (this.teams[i].score === 0) {
        updatedTeams.push(this.teams[i]);
      }
    }
    this.teams = updatedTeams;
    if (this.teams.length === 1) {
      this.winner = this.teams[0].name;
    }
  }
}

export default Game_Fight;

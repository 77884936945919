class Config_Normal {
  laps: number = 3;
  thinkingTime: number = 5;

  changeLapsValue(state: boolean) {
    switch (state) {
      case true:
        if (this.laps < 10) {
          this.laps++;
        }
        break;
      case false:
        if (this.laps > 3) {
          this.laps--;
        }
        break;
      default:
    }
  }

  changeThinkingTimeValue(increase: boolean) {
    switch (increase) {
      case true:
        if (this.thinkingTime < 60) {
          this.thinkingTime += 5;
        }
        break;
      case false:
        if (this.thinkingTime > 5) {
          this.thinkingTime -= 5;
        }
        break;
      default:
    }
  }
}

export default Config_Normal;

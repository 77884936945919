import React, { Component } from "react";
import styled from "styled-components";
import '../css/round.css';
import star from '../assets/img/star.png';
import { gameTripleInstance } from '../game';


interface IRoundPageState {
  myInterval: any;
}

const initialState: IRoundPageState = {
  myInterval: undefined
};

interface IRoundPageProps {
  history: any;
}

class Round extends Component<IRoundPageProps,IRoundPageState> {

  constructor(props: IRoundPageProps) {
    super(props);
    this.state = initialState;
  }

  Container = styled.div`
    max-width: 40rem;
    padding: 7rem 0 1rem;
    width: 95vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `;

  Buttons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  TopButtons = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0;
  `;

  ControlButtons = styled.div`
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: 2rem;

    & p {
      font-size: .8rem;
      margin-top: .5rem
    }
  `;

  Word = styled.p`
    width: 100%;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #333;
  `;

  Button = styled.button`
    width: 4rem;
    height: 4rem;
    background-Color: #fff;
    border-Radius: 2rem;
    display: flex;
    align-Items: center;
    justify-Content: center;
    border: none;
    outline: none;
    color: #333;
    position: relative;

    & div {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      left: 0%;
      top: 0%;
      background-color: #E53935;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  FinishingButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;

    & div {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        padding-top: .3rem;
        font-size: .8rem;
        color: #fff;  
      }
    }
  `;

  Banner = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #fff;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.3rem;
  margin: 1.5rem 0 0 0;
  text-align: center;
  `;

  componentDidMount() {
    if(gameTripleInstance.isComingFromHistory) {
      gameTripleInstance.setDataFromHistory();
    } else {
      gameTripleInstance.initializeRound();
    }
    this.forceUpdate();
  }
  
  handlePlay = () => {
    gameTripleInstance.play();
    // this interval for timer
    let interval = setInterval(() => {
      this.forceUpdate();
      if(gameTripleInstance.roundFinished) {
        clearInterval(interval)
        this.props.history.push('/game_triple_result');
      }
    },100)
  }

  handleAnswer = (isCorrect: boolean) => {
    gameTripleInstance.handleAnswer(isCorrect);
    this.forceUpdate();
  }

  render() {
    return (
      <this.Container>
        <this.Banner>
          <img src={star} alt='Star'/>
          <p>نوبت {gameTripleInstance.teams[gameTripleInstance.turn-1].name}</p>
          <img src={star} alt='Star'/>
        </this.Banner>
        {!gameTripleInstance.roundStarted ? 
        <this.Banner style={{fontSize: '1rem',marginTop: '0'}}>
        <img src={star} alt='Star'/>
        {gameTripleInstance.currentLap === 1 ? <p>کلمه را توضیح دهید</p> :
        gameTripleInstance.currentLap === 2 ? <p>کلمه را اجرا کنید</p> :
        <p>با یک کلمه توضیح دهید</p>}
        <img src={star} alt='Star'/>
      </this.Banner> : undefined}
        <this.Word>{gameTripleInstance.roundStarted ? gameTripleInstance.word : 'بازی شروع نشده است'}</this.Word>
        <this.Buttons>
          {!gameTripleInstance.roundStarted ?
          <this.ControlButtons>
            <this.Button onClick={() => this.handlePlay()}>
              <i className="fa fa-play" style={{fontSize: '1.5rem'}}/>
            </this.Button>
            <p>شروع</p>
          </this.ControlButtons> : undefined}
          <this.TopButtons>
            <div className='timer' style={gameTripleInstance.roundStarted ? {animationName: 'timer'} : undefined}>{gameTripleInstance.time}</div>
          </this.TopButtons>
          {gameTripleInstance.roundStarted ?
          <this.FinishingButtons>
          <div onClick={() => this.handleAnswer(true)}>
            <this.Button>
              <i className="fa fa-check" style={{color: '#388E3C',fontSize: '1.5rem'}}/>
            </this.Button>
            <p>درست گفت</p>
          </div>
          <div onClick={() => this.handleAnswer(false)}>
            <this.Button>
              <i className="fa fa-times" style={{color: "#E53935",fontSize: '1.5rem'}}/>
            </this.Button>
            <p>خطا داشت</p>
          </div>
        </this.FinishingButtons> : null}
        </this.Buttons>
      </this.Container>
    );
  }
}

export default Round;

class Config_Quick {
    gameTime: number = 60;
    difficulty: number = 1;
    selectingCategories = 'auto';

    changeGameTimeValue (state: boolean) {
        switch (state) {
            case true:
                if (this.gameTime < 300) {
                    this.gameTime += 10;
                }
                break;
            case false:
                if (this.gameTime > 60) {
                    this.gameTime -= 10;
                }
                break;
            default:
        }
    }

    changeDifficultyValue (difficulty: number) {
        this.difficulty = difficulty;
    }

    changeSelectingCategoriesValue (value: string) {
        this.selectingCategories = value;
    }
}

export default Config_Quick;
import Team from "./team";
import getdb from "../database/getdb";
import {configQuickInstance,teamsInstance,gamesHistoryInstanse,gameQuickInstance,category } from ".";
import Teams from "./teams";

class Game_Quick {
  historyGameId: number = 0;
  isComingFromHistory: boolean = false;
  mode: string = 'quick';
  gameId: number = 0;
  selectedCategories: Array<number> = [];
  started: boolean = false;
  gameFinished: boolean = false;
  teams: Team[];
  turn: number;
  word: string = "بازی شروع نشده است";
  time: number = 0;
  winner: string = '';
  interval: any;

  constructor() {
    this.teams = teamsInstance.teams;
    this.turn = 1;
    this.selectedCategories = category.selectedCategories;
  }

  configure(configurations: Teams) {
    this.teams = configurations.teams;
    this.gameFinished = false;
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    if(games !== null) {
      this.gameId = games.length + 1;
    } else {
      this.gameId = 1;
    }
  }

  setIsComingFromHistory = (gameId: number) => {
    this.historyGameId = gameId;
    this.gameId = gameId;
    this.isComingFromHistory = true;
  }

  setDataFromHistory = () => {
    let games = gamesHistoryInstanse.getGamesHistory('Games');
    games.map((item: any) => {
      if(item.gameId === this.historyGameId) {
        this.teams = item.teams;
        if(item.time === 0) {
          this.time = configQuickInstance.gameTime;
        } else {
          this.time = item.time;
        }
        this.gameId = item.gameId;
        this.historyGameId = item.gameId;
        this.started = false;
        this.turn = item.turn;
        this.word = item.word;
        this.interval = item.interval;
      }
      return null;
    })
    gamesHistoryInstanse.saveGames(gameQuickInstance);
  }

  setWord = (): Promise<any> => {
    let iWillSetWord = new Promise((resolve, reject) => {

      if(this.selectedCategories.length > 0) {
        let randomCategory = Math.floor(Math.random()*this.selectedCategories.length+1);
        let result = getdb(Number(this.selectedCategories[randomCategory-1]), Number(configQuickInstance.difficulty));
        result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));
        resolve(1);
      }
      else {
        let randomCategory: number = Math.floor(Math.random()*20+1);
        let result = getdb(Number(randomCategory), Number(configQuickInstance.difficulty));
        result
        .then((res: any) => {
          this.word = res;
          resolve(res);
        })
        .catch((error) => reject(error));
        resolve(1);
      }
    });
    return iWillSetWord;
  };

  changeWord() {
    this.setWord();
  }

  initializeGame() {
    this.turn = 1;
  }

  initializeRound() {
    this.time = configQuickInstance.gameTime;
    this.started = false;
    this.gameFinished = false;
  }

  play() {
    this.started = true;
    if(this.isComingFromHistory === false) {
      this.setWord();
    }
    this.interval = setInterval(() => {
      if (this.time > 0) {
        this.time--;
        gamesHistoryInstanse.saveGames(gameQuickInstance);
      }
      if (this.time === 0) {
        this.end();
      }
    },1000);
  }

  end() {
    this.started = false;
    this.checkTurn();
    clearInterval(this.interval);
  }

  checkTurn () {
    if (this.teams.length > this.turn) {
      this.turn++;
      this.time = configQuickInstance.gameTime;
      this.initializeRound();
    } else {
      this.gameFinished = true;
      this.setWinner();
    }
    gamesHistoryInstanse.saveGames(gameQuickInstance);
  }

  handleAnswer (index: number,isCorrect: boolean) {
    if(isCorrect) {
      this.teams[index-1].score++;
    }
    this.setWord();
    gamesHistoryInstanse.saveGames(gameQuickInstance);
  }

  setWinner() {
    let groupScores: any = [];
    this.teams.map((item: any) => {
      groupScores.push(item.score);
      return null;
    });
    const maxScore: number = Math.max(...groupScores);

    if (this.checkDuplicate(maxScore, groupScores) > 1) {
      this.winner = "بازی مساوی شد";
    } else {
      this.teams.map((item: any) => {
        if (item.score === maxScore) {
          this.winner = item.name + " برنده شد ";
        }
        return null;
      });
    }
  }

  private checkDuplicate(maxScore: number, groupScores: any) {
    let duplicate: number = 0;

    for (let i = 0; i < this.teams.length; i++) {
      if (groupScores[i] === maxScore) {
        duplicate++;
      }
    }

    return duplicate;
  }
}

export default Game_Quick;

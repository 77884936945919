import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import star from '../assets/img/star.png';
import actor from '../assets/img/actor.png';
import timer from '../assets/img/timer.png';
import fight from '../assets/img/fight.png';
import triple from '../assets/img/triple.png';
import Backpage from '../components/backpage';
import {wordsAndCategoriesInstanse} from '../game'

interface ISettingPageState {
}

interface ISettingPageProps {
}

class Setting extends Component<ISettingPageProps,ISettingPageState> {

    Container = styled.div`
        max-width: 40rem;
        width: 95vw;
        padding: 5rem 0 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    Banner = styled.div`
        width: 100%;
        height: 3rem;
        background-color: #fff;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1.3rem;
        margin: 1.5rem 0 .5rem;
    `;

    Modes = styled.div`
        width: 100%;
        height: 12rem;
        background-color: #fff;
        border-radius: 2rem;
        font-size: 1.3rem;
        margin: .3rem 0;
        position: relative;
        overflow: hidden;

        & a,
        a:active {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            text-decoration: none;
            color: #333;
        }
    `;

    componentWillMount() {
        wordsAndCategoriesInstanse.getWordsAndCategories();
        if(wordsAndCategoriesInstanse.wordsAndCategories) {
            wordsAndCategoriesInstanse.wordsAndCategories.categories.map((item: any) => {
                wordsAndCategoriesInstanse.addCategoryToJsonFile(item);
                return null;
            })
        }
        if(wordsAndCategoriesInstanse.wordsAndCategories) {
            wordsAndCategoriesInstanse.wordsAndCategories.words.map((item: any) => {
                wordsAndCategoriesInstanse.addWordToJsonFile(item);
                return null;
            })
        }
    }

    render() {
        return (
            <>
                <Backpage to='/'/>
                <this.Container>
                    <this.Banner>
                        <img src={star} alt='Star'/>
                        <p>حالت بازی</p>
                        <img src={star} alt='Star'/>
                    </this.Banner>
                    <this.Modes>
                        <Link to='/setting_normal'>
                            <img src={actor} alt='actor'/>
                            <p>معمولی</p>
                        </Link>
                    </this.Modes>
                    <this.Modes>
                        <Link to='/setting_quick'>
                            <img src={timer} alt='timer'/>
                            <p>سرعتی</p>
                        </Link>
                    </this.Modes>
                    <this.Modes>
                        <Link to='/setting_fight'>
                            <img src={fight} alt='fight'/>
                            <p>مبارزه ای</p>
                        </Link>
                    </this.Modes>
                    <this.Modes>
                        <Link to='/setting_triple'>
                            <img src={triple} alt='triple'/>
                            <p>سه گانه</p>
                        </Link>
                    </this.Modes>
                </this.Container>
            </>
        );
    }
}

export default Setting;
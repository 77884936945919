import * as Categories from '../database/categories.json';
import * as Words from '../database/content.json';

class Games_History {
    wordsAndCategories: {categories: Array<string>,words: Array<any>} = {categories: [],words: []}; 

    // constructor() {
    //     this.wordsAndCategories = {categories: [],words: []};
    // }

    addCategoryToJsonFile (name: string) {
        this.getWordsAndCategories();
        if(this.wordsAndCategories) {
            let obj: any = {
                ID: String(Categories.RECORDS.length + 3),
                CategoryName: name,
                Img: 'fa fa-user-plus'
            }
            Categories.RECORDS.push(obj);
        }
    }

    removeCategoryFromJsonFile (inputIndex: number) {
        this.getWordsAndCategories();
        if(this.wordsAndCategories) {
            Categories.RECORDS.map((item: any,index: number) => {
                if(item.CategoryName === this.wordsAndCategories.categories[inputIndex]) {
                    Categories.RECORDS.splice(index,1);
                }
                return null;
            })
        }
    }

    saveCategory (name: any) {
        console.log(this.wordsAndCategories.categories);
        this.getWordsAndCategories();
        this.wordsAndCategories.categories.push(name);
        localStorage.setItem('Words',JSON.stringify(this.wordsAndCategories));
        this.addCategoryToJsonFile(name);
    }

    removeCategory (index: number) {
        this.removeCategoryFromJsonFile(index);
        this.getWordsAndCategories();
        this.wordsAndCategories.categories.splice(index,1);
        localStorage.setItem('Words',JSON.stringify(this.wordsAndCategories));
    }

    saveWord (state: any) {
        this.getWordsAndCategories();
        let categoryId = 0;
        Categories.RECORDS.map((item: any) => {
            if(state.categorySelect === item.CategoryName) {
                categoryId = item.ID;
            }
            return null;
        })
        let obj: any = {
            content: state.wordInput,
            category: categoryId,
            point: state.pointSelect
        }
        this.wordsAndCategories.words.push(obj);
        localStorage.setItem('Words',JSON.stringify(this.wordsAndCategories));
        this.addWordToJsonFile(obj);
    }

    removeWord (index: number) {
        this.removeWordFromJsonFile(index);
        this.getWordsAndCategories();
        this.wordsAndCategories.words.splice(index,1);
        localStorage.setItem('Words',JSON.stringify(this.wordsAndCategories));
    }

    addWordToJsonFile (object: any) {
        this.getWordsAndCategories();
        if(this.wordsAndCategories) {
            let obj: any = {
                ID: String(Words.RECORDS.length + 1),
                content: object.content,
                cat_id: String(object.category),
                point: String(object.point)
            }
            Words.RECORDS.push(obj);
        }
    }

    removeWordFromJsonFile (inputIndex: number) {
        this.getWordsAndCategories();
        if(this.wordsAndCategories) {
            Words.RECORDS.map((item: any,index: number) => {
                if(item.content === this.wordsAndCategories.words[inputIndex].content) {
                    Words.RECORDS.splice(index,1);
                }
                return null;
            })
        }
        console.log(Words.RECORDS[Words.RECORDS.length-1]);
    }

    getWordsAndCategories () {
        let result: any;
        result = localStorage.getItem('Words');
        if(result !== null) {
            this.wordsAndCategories = JSON.parse(result);
            console.log('OKkkkkkkkkkkkkkkkk');
        }
    }
}

export default Games_History;